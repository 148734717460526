import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Done } from '@mui/icons-material';
import Loader from '../../../components/loader/Loader';
import Button from '../../../components/buttons/button/Button';
import {
  getEmbedCodeEnrichmentError,
  getEmbedCodeEnrichmentLoading,
  getEmbedCodeEnrichmentSuccessStatus,
} from '../../../redux/selector/biSelector';
import { enrichPowerBiEmbedCode } from '../../../redux/slicers/biSlicer';
import CopyableLink from '../../../components/copyable-link/CopyableLink';

export function EmbedCodesModal({ closeModal }) {
  const { user } = useAuth0();
  const { t } = useTranslation(['bi', 'common']);
  const dispatch = useDispatch();

  const error = useSelector(getEmbedCodeEnrichmentError);
  const loading = useSelector(getEmbedCodeEnrichmentLoading);
  const success = useSelector(getEmbedCodeEnrichmentSuccessStatus);

  const [selectedFile, setSelectedFile] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    noClick: !!selectedFile,
    noKeyboard: !!selectedFile,
    onDrop: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
    },
  });

  function renderEmbedCodes() {
    return (
      <>
        <div className="text-container">
          {t('uploadEmbedCodes.secondTitle')}
          <div className="text">
            <Trans i18nKey="uploadEmbedCodes.description" t={t}>
              <CopyableLink text={t('uploadEmbedCodes.link')} link="https://app.powerbi.com/admin-portal/embedCodes" />
            </Trans>
          </div>
        </div>
        <div>
          <br />
          <div className={classNames('dnd-container', { disabled: !!selectedFile })}>
            <div className="dnd-content" {...getRootProps()}>
              <input {...getInputProps()} />
              <FileUploadIcon />
              {t('uploadEmbedCodes.dropFiles')}
            </div>
          </div>
          <br />
          {selectedFile && (
            <div className="selected-file">
              <Done />
              {selectedFile.name}
            </div>
          )}
        </div>
        <div className="buttons-row no-margin">
          <Button isFilled={false} text={t('general.close', { ns: 'common' })} onClick={closeModal} />
          <Button
            text={t('general.upload', { ns: 'common' })} // upload
            disabled={!selectedFile}
            onClick={() => dispatch(enrichPowerBiEmbedCode({ file: selectedFile, email: user.email }))}
            isFilled
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="title">{t('uploadEmbedCodes.title')}</div>
      {loading && <Loader />}
      {error && (
        <div className="error centered">
          <div>{t('uploadEmbedCodes.error')}</div>
          <div>{error}</div>
        </div>
      )}
      {success && (
        <div className="success centered">
          <div>{t('uploadEmbedCodes.success')}</div>
        </div>
      )}
      {!loading && !error && !success && renderEmbedCodes()}
      {(error || success) && (
        <div className="buttons-row">
          <Button text={t('general.done', { ns: 'common' })} onClick={closeModal} isFilled />
        </div>
      )}
    </>
  );
}
