import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/buttons/button/Button';
import CloseableModal from '../../../components/modal/ClosableModal';
import { createPowerPlatformEnvironments } from '../../../redux/slicers/accountSlicer';
import {
  getAccounts,
  getPowerPlatformEnvironmentsErrorMessage,
  isPowerPlatformEnvironmentsLoading,
} from '../../../redux/selector/accountsSelector';
import Loader from '../../../components/loader/Loader';
import PowerPlatformIntegrationError from './PowerPlatformIntegrationError';
import { getEnvironments } from '../../../redux/selector/environmentsSelector';
import { EnvironmentType } from './EnvironmentType';

export default function PowerPlatformIntegrationEnvironmentConfirmationModal({ open, setOpen, envsToIntegrate }) {
  const dispatch = useDispatch();
  const accounts = useSelector(getAccounts);
  const existingEnvironments = useSelector(getEnvironments);
  const isLoading = useSelector(isPowerPlatformEnvironmentsLoading);
  const error = useSelector(getPowerPlatformEnvironmentsErrorMessage);
  const { user } = useAuth0();
  const { t } = useTranslation(['onboarding', 'common']);

  const isConditionalAccessError = error && error.includes('AADSTS53003:');
  const { clientId, platformTenantId } = accounts.length ? accounts[0].data : {};

  function dispatchCreateEnvironments() {
    dispatch(
      createPowerPlatformEnvironments({
        accountId: accounts[0].id,
        environments: envsToIntegrate,
        clientId,
        platformTenantId,
        email: user.email,
        powerBiEnvExists: existingEnvironments.some((env) => env.type === EnvironmentType.PowerBI),
      }),
    );
  }

  return (
    <CloseableModal open={open} closeModal={() => setOpen(false)}>
      <div className="title">{t('general.areYouSure', { ns: 'common' })}</div>
      {isLoading && <Loader />}
      {error && !isConditionalAccessError && (
        <div className="error centered">
          <div className="emoji">💔</div>
          <div>{t('environmentIntegration.environmentsIntegrationFailed')}</div>
          <div>{error}</div>
        </div>
      )}
      {isConditionalAccessError && (
        <div className="error centered">
          <div className="emoji">💔</div>
          <PowerPlatformIntegrationError isEnvironmentError />
        </div>
      )}
      {!isLoading && !error && (
        <>
          <div className="text-container">
            <div className="text">{t('environmentIntegration.confirmation', { count: envsToIntegrate.length })}</div>
            <div className="text">{t('environmentIntegration.integrationProcessMessage')}</div>
          </div>
          <div className="buttons-row">
            <Button isFilled={false} text={t('general.cancel', { ns: 'common' })} onClick={() => setOpen(false)} />
            <Button isFilled text={t('general.approve', { ns: 'common' })} onClick={dispatchCreateEnvironments} />
          </div>
        </>
      )}
    </CloseableModal>
  );
}
