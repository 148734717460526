import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { useAuth0 } from '@auth0/auth0-react';
import {
  createNotificationPolicyLoading,
  editNotificationPolicyLoading,
  getNotificationChannels,
  getNotificationPolicyById,
  getNotificationPolicyFilters,
} from '../../../redux/selector/notificationSelector';
import NotificationInputField from './NotificationInputContainer';
import Loader from '../../../components/loader/Loader';
import {
  createNotificationPolicy,
  setPolicyFilters,
  updateNotificationPolicy,
} from '../../../redux/slicers/notificationsSlicer';
import Filters from '../../../components/filter/Filters';
import { ColumnType } from '../../../components/table/ColumnOptions';
import NotificationPolicyChannelsDropdown from './NotificationPolicyChannelsDropdown';
import NotificationPolicyReminder from './NotificationPolicyReminder';
import { ReminderInterval } from './NotificationConsts';

const NAME = 'name';

const INTERVALS = {
  [ReminderInterval.DAILY]: '0 0 * * *',
  [ReminderInterval.WEEKLY]: '0 0 * * 0',
  [ReminderInterval.MONTHLY]: '0 0 1 * *',
};

const INTERVALS_REVERSE = Object.entries(INTERVALS).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});

export default function NotificationPolicyCreateDrawer({ policyId, closeDrawer, setToastOpen }) {
  const { t } = useTranslation(['settings']);
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const policy = useSelector(getNotificationPolicyById(policyId));
  const loading = useSelector(policyId ? editNotificationPolicyLoading : createNotificationPolicyLoading);
  const channels = useSelector(getNotificationChannels);

  const [name, setName] = useState('');

  const [fieldErrors] = useState({});
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [reminderActive, setReminderActive] = useState(false);
  const [reminderInterval, setReminderInterval] = useState(ReminderInterval.DAILY);
  const [maxReminders, setMaxReminders] = useState(10);

  const columns = [
    {
      Header: 'severity',
      backendKey: 'severity',
      type: ColumnType.STRING,
    },
    {
      Header: 'type',
      backendKey: 'type',
      type: ColumnType.STRING,
    },
  ];

  // FIXME get values from backend
  function getColumnOptions(column) {
    if (column.backendKey === 'severity') {
      return ['3', '2', '1', '0', '-1'];
    }
    return ['type1', 'type2', 'type3', 'type4', 'type5'];
  }

  function handleFiltersChange(newFilters) {
    dispatch(setPolicyFilters({ filters: newFilters }));
  }

  useEffect(() => {}, [policy?.filters]);

  useEffect(() => {
    if (policy) {
      dispatch(setPolicyFilters({ filters: policy.filters.filters }));
      setName(policy.name);
      const channelIds = policy.notificationChanelPolicies.map((channel) => channel.notificationChannelId);
      setSelectedChannels(channelIds);
      if (policy.reminderInterval) {
        setReminderActive(true);
        setReminderInterval(INTERVALS_REVERSE[policy.reminderInterval]);
        setMaxReminders(policy.maxReminders);
      }
    } else {
      dispatch(setPolicyFilters({ filters: [] }));
    }
  }, [policy]);

  function handleChannelChange(channelId, isSelected) {
    if (isSelected) {
      setSelectedChannels([...selectedChannels, channelId]);
    } else {
      setSelectedChannels(selectedChannels.filter((id) => id !== channelId));
    }
  }

  // FIXME implement validation
  function validate() {
    return true;
  }

  async function onApprove() {
    const dataValid = validate();
    if (!dataValid) {
      return;
    }
    const body = {
      name,
      auditUserName: user.name,
      auditUserEmail: user.email,
      channelIds: selectedChannels,
      filters: {
        joinOperator: 'AND',
        filters: [],
      },
    };

    if (reminderActive) {
      body.reminderInterval = INTERVALS[reminderInterval] || null;
      body.maxReminders = maxReminders;
    } else {
      body.reminderInterval = null;
      body.maxReminders = null;
    }

    dispatch(policyId ? updateNotificationPolicy({ policyId, body }) : createNotificationPolicy({ body }));
    setToastOpen(true);
    closeDrawer();
  }

  return (
    <div className="notification-policy-drawer">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="notification-policy-drawer-title">{t('notifications.policiesTable.drawer.title')}</div>
          <div className="notification-policy-drawer-description">
            <ReportProblemOutlinedIcon />
            {t('notifications.policiesTable.drawer.description')}
          </div>

          <NotificationInputField
            field={NAME}
            value={name}
            error={fieldErrors[NAME]}
            setValue={setName}
            name={t('notifications.policiesTable.columns.name')}
          />

          <div>
            <Filters
              tableName="insights"
              columns={columns}
              getColumnOptions={getColumnOptions}
              getFiltersSelector={getNotificationPolicyFilters}
              handleFiltersChange={handleFiltersChange}
            />
          </div>
          <NotificationPolicyChannelsDropdown
            selectedChannels={selectedChannels}
            channels={channels}
            handleChannelChange={handleChannelChange}
          />
          <NotificationPolicyReminder
            reminderActive={reminderActive}
            setReminderActive={setReminderActive}
            reminderInterval={reminderInterval}
            setReminderInterval={setReminderInterval}
            maxReminders={maxReminders}
            setMaxReminders={setMaxReminders}
            closeDrawer={closeDrawer}
            onApprove={onApprove}
          />
        </>
      )}
    </div>
  );
}
