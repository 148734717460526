import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import Table from '../../../components/table/Table';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import { mapDate, mapSeverityFromCode } from '../../../services/MapperUtils';
import Tags from '../../../components/tags/Tags';
import SeverityBox from '../../../components/box/SeverityBox';
import BooleanValue from '../../../components/boolean-value/BooleanValue';
import { StyledSwitch } from '../../../components/StyledSwitch';
import CircledText from '../../../components/circled-text/CircledText';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import InsightScope from '../../../components/insight-scope/InsightScope';
import EllipsisText from '../../../components/ellipsis-text/EllipsisText';

export default function UserInsightsTable({ isLoading, insights = [] }) {
  const [showClosed, setShowClosed] = useState(false);
  const { t } = useTranslation(['common', 'single-user']);

  const tableName = t('insightsTable.tableName', { ns: 'single-user' });
  const columns = useMemo(
    () => [
      {
        Header: t('insights.scope', { ns: 'common' }),
        accessor: 'scope',
        width: '6%',
        Cell: ({ value }) => <InsightScope insightScopeSubType={value} />,
        sortType(rowA, rowB) {
          return rowA.original.scope.localeCompare(rowB.original.scope);
        },
      },
      {
        Header: t('insights.insightName', { ns: 'common' }),
        accessor: 'insightName',
        width: '10%',
        Cell: ({ value, cell }) => (
          <OrganizationAwareLink to={`/insights/${cell.row.original.id}?`}>{value}</OrganizationAwareLink>
        ),
      },
      {
        Header: t('general.resource', { ns: 'common' }),
        accessor: 'resourceName',
        width: '10%',
        Cell: ({ value }) => <EllipsisText text={value} />,
      },
      {
        Header: t('general.environment', { ns: 'common' }),
        accessor: 'environments',
        width: '8%',
        Cell: ({ value, cell }) => {
          if (!value?.length) {
            return <div className="gray">N/A</div>;
          }

          const tags = value.map((tag) => ({ name: tag }));
          return <Tags tags={tags} tooltipAnchor={`environment-${cell.row.id}`} />;
        },
        sortType(rowA, rowB) {
          const envA = rowA.original.environments.toSorted().join();
          const envB = rowB.original.environments.toSorted().join();
          return envA.localeCompare(envB);
        },
      },
      {
        Header: t('general.creationDate', { ns: 'common' }),
        accessor: 'creationDate',
        width: '8%',
        Cell: ({ value }) => mapDate(value) || t('general.na', { ns: 'common' }),
      },
      {
        Header: t('insights.severity', { ns: 'common' }),
        accessor: 'severity',
        width: '5%',
        Cell: ({ value }) => <SeverityBox severity={mapSeverityFromCode(value)} />,
      },
      {
        Header: t('general.inProduction', { ns: 'common' }),
        accessor: 'inProduction',
        width: '5%',
        Cell: ({ value }) => <BooleanValue value={value} />,
      },
      {
        Header: t('general.status', { ns: 'common' }),
        accessor: 'status',
        width: '10%',
        Cell: ({ cell }) => {
          const status = cell.value;
          return <CircledText text={status} />;
        },
      },
    ],
    [],
  );
  const sortBy = [{ id: 'creationDate', desc: true }];
  const activeInsights = insights.filter((insight) => insight.active);
  const insightsToShow = showClosed ? insights : activeInsights;

  return (
    <div className="card-view-table-container">
      <div className="collapse-card-container">
        <CollapseCard
          totalElementsCount={insightsToShow.length}
          title={tableName}
          isLoading={isLoading}
          headerComponents={[
            <div className="show-closed-toggle">
              <span>{t('insights.showClosed', { ns: 'common' })}</span>
              <StyledSwitch
                checked={showClosed}
                onChange={() => setShowClosed(!showClosed)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>,
          ]}
        >
          <Table
            columns={columns}
            data={insightsToShow}
            totalCount={insightsToShow.length}
            options={{ showFilter: false, showPagination: true, showSort: true, usePaginationSearchParam: false }}
            type="users"
            shouldScrollOnPagination={false}
            sortBy={sortBy}
            initialFilters={[]}
            customFilters={[]}
            isRowDisabled={() => false}
            getElementId={(insight) => insight.id}
            isLoading={isLoading}
            classNames="card-view-table"
            backendPagination={false}
            pageSize={5}
            emptyState={
              <TableEmptyContent
                img={emptyApplicationsImg}
                mainText={t(showClosed ? 'insightsTable.noData' : 'insightsTable.noDataOnActive', {
                  ns: 'single-user',
                })}
              />
            }
          />
        </CollapseCard>
      </div>
    </div>
  );
}
