import { useState } from 'react';
import classNames from 'classnames';
import { Menu, MenuItem } from '@mui/material';
import './dropdown-box.scss';
import checkIcon from './check_icon.svg';
import Arrow from '../arrow/Arrow';

export default function DropdownBox({
  id = 'dropdown-menu',
  menuItems = [],
  text,
  value,
  selectedId,
  width = 200,
  shouldCheckIcon = true,
  disabled = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div
      className={classNames('dropdown-box', { disabled })}
      onClick={(event) => {
        if (!disabled) {
          setAnchorEl(event.currentTarget.children[1]);
        }
      }}
    >
      {text && <div className="text">{text}:</div>}
      <div className="name">{value}</div>
      <Arrow value={anchorEl ? 'up' : 'down'} />
      {!!anchorEl && (
        <Menu
          id={id}
          anchorEl={anchorEl}
          open={!!anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={(e) => {
            e.stopPropagation();
            setAnchorEl(null);
          }}
        >
          {menuItems.map((item) => (
            <MenuItem
              key={item.id}
              sx={{ width: `${width}px` }}
              onClick={() => {
                setAnchorEl(null);
                item.onClick(item.text);
              }}
            >
              {shouldCheckIcon && (
                <div className="check-icon-container">
                  {selectedId === item.id && <img src={checkIcon} alt="check-icon" />}
                </div>
              )}
              <div className="text">{item.text}</div>
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
}
