import Input from '../../../components/input/Input';

export default function NotificationInputField({ name, field, value, setValue, error }) {
  return (
    <div className="notification-input-field">
      <div>{name}</div>
      <Input
        id={name}
        value={value}
        setValue={setValue}
        placeholder={name}
        required={field.required}
        type={field.type}
        error={error}
      />
    </div>
  );
}
