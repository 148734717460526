import { startCase } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import KeyValueElement from '../../../components/key-value-element/KeyValueElement';
import { getLogo } from '../../../services/LogoService';
import PersonData from '../../../components/person-data/PersonData';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import { getIsGuest } from '../../../redux/selector/authSelector';

export default function InsightAppData({ application, linksBreadcrumbs }) {
  const isGuest = useSelector(getIsGuest);
  const { t } = useTranslation(['common', 'single-insight']);

  const { platformName } = application.account;
  const logo = getLogo(platformName);

  const { owner } = application;

  return (
    <div className="insight-app-data row">
      <KeyValueElement
        title={t('applications.applicationName')}
        value={
          <OrganizationAwareLink to={`/applications/${application.id}?from=${linksBreadcrumbs}`} disabled={isGuest}>
            {application.name}
          </OrganizationAwareLink>
        }
        isLink={!isGuest}
      />
      <KeyValueElement title={t('general.platform')} value={platformName} img={logo} />
      {owner && (
        <KeyValueElement
          title={t('general.owner')}
          value={<PersonData owner={owner} linksBreadcrumbs={linksBreadcrumbs} />}
        />
      )}
      <KeyValueElement title={t('general.status')} value={startCase(application.status.toLowerCase())} />
    </div>
  );
}
