import NotificationSettingsPage from './notifications/NotificationSettingsPage';
import './settings.scss';

export default function SettingsPage() {
  // TODO return different page based on route
  return (
    <div className="settings-page">
      <NotificationSettingsPage />
    </div>
  );
}
