import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SwipeableDrawer } from '@mui/material';
import { capitalize } from 'lodash';
import { formatDistanceToNow } from 'date-fns';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import NotificationBox from './NotificationBox';
import './notifications.scss';
import nextNotificationIcon from './next_notification_icon.svg';
import closeIcon from './close_icon.svg';
import emptyNotificationIcon from './empty_notifications.svg';
import PageHeader from '../../../../components/page-header/PageHeader';
import { fetchInsightNotifications } from '../../../../redux/slicers/insightSlicer';
import {
  getInsightNextNotificationTime,
  getInsightNotifications,
  getNotificationsLoading,
} from '../../../../redux/selector/insightsSelector';
import Loader from '../../../../components/loader/Loader';
import notificationIcon from './notification_icon.svg';
import SendNotificationModal from './SendNotificationModal';
import { getNotificationChannels } from '../../../../redux/selector/notificationSelector';
import { fetchNotificationsChannels } from '../../../../redux/slicers/notificationsSlicer';

function NextNotification({ nextTime }) {
  const { t } = useTranslation(['notifications']);

  return (
    <div className="next-notification">
      <img src={nextNotificationIcon} alt="next-notification" />
      <span>{t('nextExpectedNotificationTime')}</span>
      <span>{nextTime}</span>
    </div>
  );
}

function getNextNotificationTime(nextNotificationTime) {
  const { t } = useTranslation(['notifications']);

  if (!nextNotificationTime) {
    return t('notScheduled');
  }

  const date = new Date(nextNotificationTime);
  return capitalize(formatDistanceToNow(date, { addSuffix: true }));
}

function EmptyNotification() {
  const { t } = useTranslation(['notifications']);

  return (
    <div className="empty-notification">
      <img src={emptyNotificationIcon} alt="empty-notification" />
      <div className="text">{t('noNotifications')}</div>
    </div>
  );
}

function SendNotificationButton({ insightId, insightOwnerEmail }) {
  const [sendNotificationModalOpen, setSendNotificationModalOpen] = useState(false);
  const { t } = useTranslation(['notifications']);

  return (
    <>
      <div className="send-notifications" onClick={() => setSendNotificationModalOpen(true)}>
        <img src={notificationIcon} alt={t('showNotifications')} />
        {t('sendNotificationsManually')}
      </div>
      <SendNotificationModal
        open={sendNotificationModalOpen}
        setOpen={setSendNotificationModalOpen}
        insightId={insightId}
        insightOwnerEmail={insightOwnerEmail}
      />
    </>
  );
}

export default function NotificationsTab({ open, setOpen, insightId, insightOwnerEmail }) {
  const dispatch = useDispatch();
  const { user } = useAuth0(); // don't allow to send notifications to guests
  const notifications = useSelector(getInsightNotifications(insightId));
  const nextNotificationTime = useSelector(getInsightNextNotificationTime(insightId));
  const notificationsLoading = useSelector(getNotificationsLoading);
  const notificationChannels = useSelector(getNotificationChannels);
  const { t } = useTranslation(['notifications']);

  useEffect(() => {
    (async function fetchData() {
      const promises = [];
      if (insightId && !notificationsLoading) {
        promises.push(dispatch(fetchInsightNotifications({ insightId })));
      }
      if (!notificationChannels) {
        promises.push(dispatch(fetchNotificationsChannels()));
      }

      if (promises.length) {
        await Promise.all(promises);
      }
    })();
  }, [insightId]);

  return (
    <SwipeableDrawer anchor="right" open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
      <div className="notifications-tab">
        <span onClick={() => setOpen(false)} className="close-icon">
          <img src={closeIcon} alt="close" />
        </span>
        <PageHeader title={t('notificationRecords')} counter={notifications.length} />
        {!notifications.length && notificationsLoading && <Loader />}
        <NextNotification nextTime={getNextNotificationTime(nextNotificationTime)} />
        {!!user && <SendNotificationButton insightOwnerEmail={insightOwnerEmail} insightId={insightId} />}
        {!notifications.length && !notificationsLoading && <EmptyNotification />}

        {!!notifications.length && (
          <>
            {notifications.map((notification) => (
              <NotificationBox
                key={`${notification.sentTime}-${notification.notificationChannel.type}`}
                trigger={notification.trigger}
                sentTime={notification.sentTime}
                channelName={notification.destination}
                channelType={notification.notificationChannel.type}
                sentSuccessfully={notification.sentSuccessfully}
                senderName={notification.senderName}
              />
            ))}
          </>
        )}
      </div>
    </SwipeableDrawer>
  );
}
