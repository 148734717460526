import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import Tags from '../../../components/tags/Tags';
import { mapDate } from '../../../services/MapperUtils';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import Table from '../../../components/table/Table';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import { StyledSwitch } from '../../../components/StyledSwitch';
import EllipsisText from '../../../components/ellipsis-text/EllipsisText';
import { StatusBullet } from '../../../components/status-bullet/StatusBullet';

export default function UserApplicationsTable({ isLoading, applications = [] }) {
  const [showClosed, setShowClosed] = useState(false);
  const { t } = useTranslation(['common', 'single-user']);

  const tableName = t('applicationsTable.tableName', { ns: 'single-user' });

  const columns = useMemo(
    () => [
      {
        Header: t('general.name', { ns: 'common' }),
        accessor: 'name',
        width: '18%',
        Cell: ({ value, cell }) => (
          <OrganizationAwareLink to={`/applications/${cell.row.original.id}?from=${value}`}>
            <EllipsisText text={value} />
          </OrganizationAwareLink>
        ),
        sortType(rowA, rowB) {
          const a = rowA.original.name;
          const b = rowB.original.name;
          return a.localeCompare(b);
        },
      },
      {
        Header: t('general.environments', { ns: 'common' }),
        accessor: 'environments',
        width: '13%',
        Cell: ({ value, cell }) => {
          const environmentNames = value?.map((tag) => ({ name: tag.trim(), isDark: false }));
          return environmentNames?.length ? (
            <Tags tags={environmentNames} tooltipAnchor={`user-${cell.row.id}`} />
          ) : (
            t('general.na')
          );
        },
        sortType(rowA, rowB) {
          const envA = rowA.original.environments.toSorted().join();
          const envB = rowB.original.environments.toSorted().join();
          return envA.localeCompare(envB);
        },
      },
      {
        Header: t('general.lastChange', { ns: 'common' }),
        accessor: 'lastModified',
        width: '15%',
        Cell: ({ value }) => (value ? mapDate(value) : '-'),
      },
      {
        Header: t('general.createDate', { ns: 'common' }),
        accessor: 'creationTime',
        width: '15%',
        Cell: ({ value }) => (value ? mapDate(value) : '-'),
      },
      {
        Header: t('general.lastRunTime', { ns: 'common' }),
        accessor: 'lastRunTime',
        width: '15%',
        Cell: ({ value }) => (value ? mapDate(value) : '-'),
      },
      {
        Header: t('general.status', { ns: 'common' }),
        accessor: 'isDeleted',
        width: '15%',
        Cell: ({ value }) => (
          <StatusBullet value={t(value ? 'general.deleted' : 'general.live', { ns: 'common' })} active={!value} />
        ),
        sortType(rowA, rowB) {
          const a = rowA.original.isDeleted;
          const b = rowB.original.isDeleted;
          if (a === b) {
            return 0;
          }
          return a ? 1 : -1;
        },
      },
      {
        Header: t('general.owner', { ns: 'common' }),
        accessor: 'owner',
        width: '15%',
        Cell: ({ value }) => value || '-',
      },
    ],
    [],
  );

  const sortBy = [{ id: 'name', desc: false }];
  const activeApplications = applications.filter((app) => !app.isDeleted);
  const appsToDisplay = showClosed ? applications : activeApplications;

  return (
    <div className="card-view-table-container">
      <div className="collapse-card-container">
        <CollapseCard
          totalElementsCount={appsToDisplay.length}
          title={tableName}
          isLoading={isLoading}
          headerComponents={[
            <div className="show-closed-toggle">
              <span>{t('applicationsTable.showDeletedToggle', { ns: 'single-user' })}</span>
              <StyledSwitch
                checked={showClosed}
                onChange={() => setShowClosed(!showClosed)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>,
          ]}
        >
          <Table
            columns={columns}
            data={appsToDisplay}
            totalCount={appsToDisplay.length}
            options={{ showFilter: false, showPagination: true, showSort: true, usePaginationSearchParam: false }}
            type="users"
            shouldScrollOnPagination={false}
            sortBy={sortBy}
            initialFilters={[]}
            customFilters={[]}
            isRowDisabled={() => false}
            getElementId={(app) => app.id}
            isLoading={isLoading}
            classNames="card-view-table"
            backendPagination={false}
            pageSize={5}
            emptyState={
              <TableEmptyContent
                img={emptyApplicationsImg}
                mainText={t('applicationsTable.tableName', { ns: 'single-user' })}
              />
            }
          />
        </CollapseCard>
      </div>
    </div>
  );
}
