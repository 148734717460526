import { startCase, uniq } from 'lodash';
import { useTranslation } from 'react-i18next';
import { mapDate } from '../../../services/MapperUtils';
import KeyValueElement from '../../../components/key-value-element/KeyValueElement';
import { getLogo } from '../../../services/LogoService';
import { DATA_ACCESS } from '../../../consts/tagConsts';
import Tags from '../../../components/tags/Tags';
import ApplicationType from '../../../components/application-type/ApplicationType';
import { getConnectorIcon } from '../../data-source/connector-icons/connectorsIconsService';
import PersonData from '../../../components/person-data/PersonData';

function FlowTrigger({ extraData = {} }) {
  const { t } = useTranslation(['common', 'single-application']);

  return (
    <div className="application-data flow-trigger">
      <div className="trigger-title">{t('invocationMethod', { ns: 'single-application' })}</div>
      <KeyValueElement
        title={t('connections.connectorType')}
        value={extraData.invocationConnector || t('general.na')}
        img={extraData.invocationConnector ? getConnectorIcon(extraData.invocationConnector) : null}
      />
      <KeyValueElement
        title={t('triggerName', { ns: 'single-application' })}
        value={extraData.invocationName || t('general.na')}
      />
      <KeyValueElement
        title={t('triggerAction', { ns: 'single-application' })}
        value={extraData.invocationAction || t('general.na')}
      />
    </div>
  );
}

export default function ApplicationData({ application, isFlow, linksBreadcrumbs }) {
  const { t } = useTranslation(['common']);

  const { platformName } = application.account;
  const creationTime = application.appInstances
    .map((instance) => instance.creationTime)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0];
  const lastModifiedTime = application.appInstances
    .map((instance) => instance.lastPublishedTime)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0];

  const tags = application.appInstances.flatMap((instance) => instance.appInstanceTags.map((ait) => ait.tag));

  const dataAccessTags = uniq(tags.filter((tag) => tag.type === DATA_ACCESS).map((tag) => tag.name));

  const businessContexts = uniq(
    application.appInstances.flatMap((instance) =>
      instance.appInstanceBusinessContexts.map((aibc) => aibc.businessContext.name),
    ),
  );

  const { owner } = application;

  return (
    <>
      <div className="application-data">
        <KeyValueElement title={t('general.platform')} value={platformName} img={getLogo(platformName)} />
        <KeyValueElement
          title={t('general.owner')}
          value={<PersonData owner={owner} linksBreadcrumbs={linksBreadcrumbs} />}
        />
        <KeyValueElement title={t('general.creationDate')} value={mapDate(creationTime)} />
        <KeyValueElement title={t('general.lastChange')} value={mapDate(lastModifiedTime)} />
      </div>
      <div className="application-data">
        <KeyValueElement title={t('general.type')} value={<ApplicationType value={application.type} />} />
        <KeyValueElement title={t('general.status')} value={startCase(application.status.toLowerCase())} />
        {businessContexts.length > 0 && (
          <KeyValueElement
            title={t('general.businessContext')}
            className="block"
            value={
              <Tags tags={businessContexts.map((tag) => ({ name: tag }))} tooltipAnchor="business-context-tooltip" />
            }
          />
        )}
        {dataAccessTags.length > 0 && (
          <KeyValueElement
            title={t('tags.dataAccess')}
            className="block"
            value={<Tags tags={dataAccessTags.map((tag) => ({ name: tag }))} tooltipAnchor="data-access-tooltip" />}
          />
        )}
      </div>
      {isFlow && <FlowTrigger extraData={application.appInstances.at(-1).extraData} />}
    </>
  );
}
