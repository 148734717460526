import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NoData from '../../../components/no-data/NoData';
import UsedByTable from './UsedByTable';
import { isUsedByApplicationsLoading } from '../../../redux/selector/applictionSelector';

export default function FlowRequests({ applications, isFlow, linksBreadcrumbs }) {
  const usedByLoading = useSelector(isUsedByApplicationsLoading);
  const { t } = useTranslation(['single-application']);

  if (!applications?.length) {
    return <NoData text={t(isFlow ? 'flowNotUsed' : 'appNotUsed')} />;
  }

  return (
    <div className="flow-requests">
      <UsedByTable apps={applications} from={linksBreadcrumbs} isLoading={usedByLoading} isFlow={isFlow} />
    </div>
  );
}
