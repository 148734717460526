import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Trans, useTranslation } from 'react-i18next';
import Toast from '../../components/toast/Toast';
import Button from '../../components/buttons/button/Button';
import CloseableModal from '../../components/modal/ClosableModal';
import { vetConnector } from '../../redux/slicers/dataSourceSlicer';
import { isVettingSuccessful } from '../../redux/selector/dataSourcesSelector';

export default function ConnectorVettingModal({ open, setOpen, connectorId, connectorName }) {
  const [toastOpen, setToastOpen] = useState(false);
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const vettingSuccessful = useSelector(isVettingSuccessful);

  const { t } = useTranslation(['connections', 'common']);

  return (
    <>
      <Toast
        open={toastOpen}
        setOpen={setToastOpen}
        isSuccess={vettingSuccessful}
        title={vettingSuccessful ? t('connectorVetted') : t('connectorVettingFailed')}
        message={vettingSuccessful ? t('connectorVettedSuccess') : t('general.pleaseTryAgain', { ns: 'common' })}
      />
      <CloseableModal open={open} closeModal={() => setOpen(false)}>
        <div className="title">{t('vetConnector')}</div>
        <div className="text-container">
          <span className="text">
            <Trans i18nKey="vetConnectorConfirm" t={t}>
              Are you sure you want to vet the connector <b>{{ connectorName }}</b>?
            </Trans>
          </span>
        </div>
        <div className="buttons-row">
          <Button isFilled={false} text={t('general.cancel', { ns: 'common' })} onClick={() => setOpen(false)} />
          <Button
            isFilled
            text={t('general.approve', { ns: 'common' })}
            onClick={async () => {
              await dispatch(vetConnector({ connectorId, vettedBy: user.name, email: user.email }));
              setToastOpen(true);
              setOpen(false);
            }}
          />
        </div>
      </CloseableModal>
    </>
  );
}
