import pageNotFoundImg from './page_not_found.svg';
import noRoleImg from './no_role.svg';

export const SystemErrorType = Object.freeze({
  NO_ORGANIZATION: 'NO_ORGANIZATION',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
  INVALID_TOKEN: 'INVALID_TOKEN',
  NO_ROLE: 'NO_ROLE',
  ILLEGAL_ORGANIZATION: 'ILLEGAL_ORGANIZATION',
  NOT_FOUND: 'NOT_FOUND',
  FAILED_TO_GET_USER_DATA: 'FAILED_TO_GET_USER_DATA',
  INVALID_INVITATION: 'INVALID_INVITATION',
});

export const systemErrors = {
  [SystemErrorType.NO_ORGANIZATION]: {
    message: 'You are not assigned to any environment\nPlease contact your administrator',
    image: noRoleImg,
    showReturnLink: false,
  },
  [SystemErrorType.EXPIRED_TOKEN]: {
    message: 'Your guest access has expired\nPlease contact your administrator or log in to continue',
    image: noRoleImg,
    showReturnLink: false,
  },
  [SystemErrorType.INVALID_TOKEN]: {
    message: 'Your guest access token is invalid\nPlease contact your administrator or log in to continue',
    image: noRoleImg,
    showReturnLink: false,
  },
  [SystemErrorType.NO_ROLE]: {
    message: 'You are not assigned to any role\nPlease contact your administrator',
    image: noRoleImg,
    showReturnLink: false,
  },
  [SystemErrorType.ILLEGAL_ORGANIZATION]: {
    message: 'Organization is illegal',
    image: pageNotFoundImg,
    showReturnLink: true,
  },
  [SystemErrorType.NOT_FOUND]: {
    message: "This page doesn't exist",
    image: pageNotFoundImg,
    showReturnLink: true,
  },
  [SystemErrorType.FAILED_TO_GET_USER_DATA]: {
    message: `Something went wrong: failed to get user data. [Go back to home page](${window.location})`,
    image: pageNotFoundImg,
    showReturnLink: false,
  },
  [SystemErrorType.INVALID_INVITATION]: {
    message: 'Invitation not found or already used',
    image: pageNotFoundImg,
    showReturnLink: true,
  },
};
