import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import './information-icon.scss';

export default function InformationIcon({ tooltipText }) {
  if (!tooltipText) {
    return null;
  }

  return (
    <Tooltip title={tooltipText} arrow>
      <InfoOutlinedIcon fontSize="small" className="information-icon" />
    </Tooltip>
  );
}
