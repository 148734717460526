import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleConnections } from '../../../redux/slicers/connectionsSlicer';
import {
  getSingleConnection,
  getSingleConnectionData,
  getSingleConnectionEmbeddedFlows,
  getSingleConnectionFlowEditors,
  getSingleConnectionLoading,
  getSingleConnectionUsers,
} from '../../../redux/selector/connectionSelector';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import Segment from '../../../components/segment/Segment';
import ConnectionDetails from './ConnectionDetails';
import ConnectionUserTable from './ConnectionUsersTable';
import ConnectionDataTable from './ConnectionDataTable';
import EmbeddedFlowsTable from './EmbeddedFlowsTable';
import { ShareType } from '../ConnectionShareType';

import '../single-connection-page.scss';

export default function SingleConnectionPage() {
  const { t } = useTranslation(['common', 'single-connection']);
  const [searchParams] = useSearchParams();
  const { connId } = useParams();
  const dispatch = useDispatch();

  const connection = useSelector(getSingleConnection(connId));
  const users = useSelector(getSingleConnectionUsers(connId));
  const data = useSelector(getSingleConnectionData(connId));
  const embeddedFlows = useSelector(getSingleConnectionEmbeddedFlows(connId));
  const flowEditors = useSelector(getSingleConnectionFlowEditors(connId));
  const isLoading = useSelector(getSingleConnectionLoading(connId));

  const fromParam = searchParams.get('from') || '';
  const linksBreadcrumbs = `${fromParam},${connection?.name}`;

  const nonOwnerUsers =
    connection?.type === ShareType.Implicit
      ? (flowEditors || []).filter((user) => user.person.id !== connection?.owner?.id)
      : (users || []).filter((user) => user.id !== connection?.owner?.id);

  useEffect(() => {
    (async function fetchSingleConnection() {
      dispatch(fetchSingleConnections(connId));
    })();
  }, []);

  return (
    <div className="connection-page single-connection-page">
      <Breadcrumbs current={connection?.name} />
      <Segment title={t('connectionDetails.title', { ns: 'single-connection' })} isLoading={isLoading}>
        <ConnectionDetails connection={connection} linksBreadcrumbs={linksBreadcrumbs} />
      </Segment>
      {connection?.shareType === ShareType.Implicit && (
        <EmbeddedFlowsTable isLoading={isLoading} flows={embeddedFlows} linksBreadcrumbs={linksBreadcrumbs} />
      )}
      <ConnectionUserTable isLoading={isLoading} users={nonOwnerUsers} shareType={connection?.shareType} />
      <ConnectionDataTable isLoading={isLoading} data={data} connectionId={connection?.id} />
    </div>
  );
}
