import { useState } from 'react';
import classnames from 'classnames';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import Arrow from '../../../components/arrow/Arrow';
import { isFinaro } from '../../../services/AuthService';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';

import './navbar.scss';

export default function Navbar() {
  const { user } = useAuth0();
  const { t } = useTranslation(['navbar', 'common']);

  const currentPath = window.location.pathname;
  const pathes = [
    '/applications',
    '/integrations',
    '/insights',
    '/settings',
    '/policy',
    '/data',
    '/aggregations',
    '/users',
    '/bi',
    '/settings',
  ];
  const currentActiveOption = pathes.find((path) => currentPath.includes(path)) || '/';

  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  return (
    <div className="navbar">
      <div className="navbar-top">
        <div className="logo" />
      </div>
      <div className="navbar-items">
        <NavbarItem paths={['/']} title={t('overview')} iconId="dashboard_icon" activeOption={currentActiveOption} />
        <NavbarItem
          paths={['/applications']}
          title={t('applications.applications', { ns: 'common' })}
          iconId="applications_icon"
          activeOption={currentActiveOption}
        />
        <NavbarItem
          paths={['/insights', '/aggregations']}
          title={t('insights.insights', { ns: 'common' })}
          iconId="insights_icon"
          activeOption={currentActiveOption}
        />

        <NavbarItem
          paths={['/data']}
          title={t('general.dataSources', { ns: 'common' })}
          iconId="data_source_icon"
          activeOption={currentActiveOption}
        />
        {!isFinaro && (
          <NavbarItem paths={['/bi']} title={t('bi')} iconId="power_bi_icon" activeOption={currentActiveOption} />
        )}
        <NavbarItem
          paths={['/users']}
          title={t('users.users', { ns: 'common' })}
          iconId="users_icon"
          activeOption={currentActiveOption}
        />
        <NavbarItem paths={['/policy']} title={t('policy')} iconId="policy_icon" activeOption={currentActiveOption} />
        {/* <NavbarItem */}
        {/*   paths={['/settings']} */}
        {/*   title={t('notifications')} */}
        {/*   iconId="notifications_icon" */}
        {/*   activeOption={currentActiveOption} */}
        {/* /> */}
      </div>

      <div className="navbar-bottom">
        <AccountCircleIcon className="account-photo" />
        <div className="profile-name-wrapper">
          <div className="profile-name">{user.name}</div>
          <Arrow value={profileMenuOpen ? 'up' : 'down'} onClick={() => setProfileMenuOpen(!profileMenuOpen)} />
          <div className={classnames('profile-menu', { open: profileMenuOpen })}>
            <ProfileMenuItem
              title={t('logout')}
              onClick={() => {
                window.dispatchEvent(new Event('logout'));
                setProfileMenuOpen(false);
              }}
            />
          </div>
        </div>
        <div className="profile-email">{user.email}</div>
      </div>
    </div>
  );

  function NavbarItem({ title, paths, iconId, activeOption }) {
    return (
      <OrganizationAwareLink to={`${paths[0]}`} className="gray-link">
        <div
          className={classnames('navbar-item', {
            'navbar-item-active': paths.includes(activeOption),
          })}
        >
          <div className="navbar-item-wrapper">
            <span className="navbar-item-icon" id={iconId} />
            <span className="navbar-item-title">{title}</span>
          </div>
        </div>
      </OrganizationAwareLink>
    );
  }
}

function ProfileMenuItem({ title, onClick }) {
  return (
    <div className="profile-menu-item" onClick={onClick}>
      {title}
    </div>
  );
}
