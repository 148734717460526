import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/selector/authSelector';
import Loader from '../../components/loader/Loader';

export default function AuthenticationGuard({ component }) {
  const user = useSelector(getUser);
  const path = window.location.pathname;

  const Component = useMemo(
    () =>
      withAuthenticationRequired(component, {
        onRedirecting: () => (user ? <Component /> : <Loader />),
      }),
    [path],
  );

  return <Component />;
}
