import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DropdownBox from '../../../components/dropdown-box/DropdownBox';
import ClosableModal from '../../../components/modal/ClosableModal';
import { EnrichWholeOrg, UploadEmbedCodes } from './EnrichComponents';
import { StatusBullet } from '../../../components/status-bullet/StatusBullet';
import EnrichModal from './EnrichModal';
import {
  fetchEnrichmentStatus,
  resetEmbedCodeEnrichmentStatus,
  resetEnrichmentStatus,
} from '../../../redux/slicers/biSlicer';
import {
  getPublicReportsEnrichmentStatus,
  getSharedReportsEnrichmentLoading,
  getSharedReportsEnrichmentStatus,
} from '../../../redux/selector/biSelector';
import { EmbedCodesModal } from './EmbedCodesModal';

const EnrichModalOptions = Object.freeze({
  EMBED_CODES: 'embedCodes',
  SHARE_WITH_ENTIRE_ORG: 'shareWithEntireOrg',
});

function isEnrichedSynced(reportStatus) {
  return reportStatus.enriched === reportStatus.total;
}

export default function EnrichDropdown() {
  const [enrichModalOpen, setEnrichModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const { t } = useTranslation(['bi']);
  const dispatch = useDispatch();

  const sharedReportsStatus = useSelector(getSharedReportsEnrichmentStatus);
  const publicReportsStatus = useSelector(getPublicReportsEnrichmentStatus);
  const loading = useSelector(getSharedReportsEnrichmentLoading);

  useEffect(() => {
    dispatch(fetchEnrichmentStatus());
  }, []);

  return (
    <div>
      <ClosableModal id="enrich-modal" open={enrichModalOpen} closeModal={() => setEnrichModalOpen(false)}>
        {modalContent === EnrichModalOptions.EMBED_CODES && (
          <EmbedCodesModal closeModal={() => setEnrichModalOpen(false)} />
        )}
        {modalContent === EnrichModalOptions.SHARE_WITH_ENTIRE_ORG && (
          <EnrichModal closeModal={() => setEnrichModalOpen(false)} />
        )}
      </ClosableModal>
      <DropdownBox
        id="bi-enrichment"
        width={200}
        text="Columns"
        value={
          <StatusBullet
            value={t('enrichDropdown')}
            active={isEnrichedSynced(sharedReportsStatus) && isEnrichedSynced(publicReportsStatus)}
            loading={loading}
          />
        }
        menuItems={[
          {
            id: 'Upload',
            text: <UploadEmbedCodes reportStatus={publicReportsStatus} loading={loading} />,
            onClick: () => {
              dispatch(resetEmbedCodeEnrichmentStatus());
              setModalContent(EnrichModalOptions.EMBED_CODES);
              setEnrichModalOpen(true);
            },
          },
          {
            id: 'Enrich',
            text: <EnrichWholeOrg reportStatus={sharedReportsStatus} loading={loading} />,
            onClick: () => {
              dispatch(resetEnrichmentStatus());
              setModalContent(EnrichModalOptions.SHARE_WITH_ENTIRE_ORG);
              setEnrichModalOpen(true);
            },
          },
        ]}
        shouldCheckIcon={false}
      />
    </div>
  );
}
