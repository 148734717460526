import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { NotificationChannelName } from './NotificationChannelName';
import Arrow from '../../../components/arrow/Arrow';
import MultiSelectDropdownButton, { CheckType } from '../../../components/multi-select-dropdown/MultiSelectDropdown';

export default function NotificationPolicyChannelsDropdown({ selectedChannels, channels, handleChannelChange }) {
  const { t } = useTranslation(['settings']);
  const [anchorEl, setAnchorEl] = useState(null);

  function toggleChannelsDropdown(e) {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget.children[1]);
    }
  }

  function mapChannelOption(channel) {
    return {
      id: channel.id,
      label: <NotificationChannelName name={channel.name} type={channel.type} />,
      isSelected: selectedChannels.includes(channel.id) ?? false,
    };
  }

  return (
    <div className="notification-policy-drawer-channels">
      <div>{t('notifications.policiesTable.columns.associatedChannels')}</div>
      <div className="dropdown-box" onClick={toggleChannelsDropdown}>
        <div className="text">
          {selectedChannels.length
            ? channels
                .filter((channel) => selectedChannels.includes(channel.id))
                .map((channel) => <NotificationChannelName key={channel.id} name={channel.name} type={channel.type} />)
            : t('notifications.policiesTable.drawer.chooseChannels')}
        </div>
        <Arrow value={anchorEl ? 'up' : 'down'} />
      </div>
      <MultiSelectDropdownButton
        isOpen={!!anchorEl}
        anchorEl={anchorEl}
        handleClose={toggleChannelsDropdown}
        items={channels.map(mapChannelOption)}
        handleSelect={handleChannelChange}
        isSortBySelected={false}
        checkType={CheckType.CHECKMARK}
      />
    </div>
  );
}
