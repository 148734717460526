export const getDevelopers = (state) => state.users.developers.content || [];
export const getDevelopersLoading = (state) => state.users.developers.loading;
export const getDevelopersTotal = (state) => state.users.developers.total;
export const getDevelopersFilters = (state) => state.users.developers.filters;
export const getDeveloperFilterOptions = (state) => state.users.developers.filtersOptions;
export const getDevelopersActivity = (state) => state.users.developersActivity.content || [];
export const getDevelopersActivityLoading = (state) => state.users.developersActivity.loading;
export const getDevelopersInsights = (state) => state.users.developersInsights.content || [];
export const getDevelopersInsightsLoading = (state) => state.users.developersInsights.loading;
export const getNewDevelopers = (state) => state.users.newDevelopers.content || [];
export const getNewDevelopersLoading = (state) => state.users.newDevelopers.loading;
export const getDeveloper = (userId) => (state) => state.users.developer[userId]?.content?.devData || {};
export const getDeveloperLoading = (userId) => (state) => state.users.developer[userId]?.loading;
export const getDeveloperApplications = (userId) => (state) =>
  state.users.developer?.[userId]?.content?.applications || [];
export const getDeveloperConnections = (userId) => (state) =>
  state.users.developer?.[userId]?.content?.connections || [];
export const getDeveloperInsights = (userId) => (state) => state.users.developer?.[userId]?.content?.insights || [];
export const getDeveloperRoles = (userId) => (state) => state.users.developer?.[userId]?.content?.roles || [];
export const getDeveloperActivity = (userId) => (state) => state.users.developer?.[userId]?.content?.activity || [];
