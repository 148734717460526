import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validate } from 'uuid';
import { useTranslation } from 'react-i18next';
import InputWithInstructions from '../InputWithInstructions';
import logo from '../logo.svg';
import Button from '../../../components/buttons/button/Button';
import PowerPlatformIntegrationAccountConfirmationModal from './PowerPlatformIntegrationAccountConfirmationModal';

import '../integration-page.scss';
import { isPowerPlatformIntegrationLoading } from '../../../redux/selector/accountsSelector';
import { setAzureConfigFinished } from '../../../redux/slicers/accountSlicer';
import AzureAuthConfig from '../../../auth/azure-auth-config';

export default function PowerPlatformIntegrationInputPage() {
  const [platformTenantId, setPlatformTenantId] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  const [platformTenantIdHasError, setPlatformTenantIdHasError] = useState(false);
  const [clientIdHasError, setClientIdHasError] = useState(false);
  const [clientSecretHasError, setClientSecretHasError] = useState(false);

  const [canSubmit, setCanSubmit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const loading = useSelector(isPowerPlatformIntegrationLoading);

  const { t } = useTranslation(['onboarding', 'common']);

  useEffect(() => {
    const allHaveValues = [platformTenantId, clientId, clientSecret].every((value) => !!value);
    const allWithoutErrors = [platformTenantIdHasError, clientIdHasError, clientSecretHasError].every(
      (value) => !value,
    );
    setCanSubmit(allHaveValues && allWithoutErrors);
  }, [platformTenantId, clientId, clientSecret, platformTenantIdHasError, clientIdHasError, clientSecretHasError]);

  function isUUID(value) {
    return !value || validate(value);
  }

  useEffect(() => {
    if (isUUID(platformTenantId)) {
      setPlatformTenantIdHasError(false);
    } else {
      setPlatformTenantIdHasError(true);
    }
  }, [platformTenantId]);

  useEffect(() => {
    if (isUUID(clientId)) {
      setClientIdHasError(false);
    } else {
      setClientIdHasError(true);
    }
  }, [clientId]);

  useEffect(() => {
    if (!clientSecret || clientSecret.length > 20) {
      setClientSecretHasError(false);
    } else {
      setClientSecretHasError(true);
    }
  }, [clientSecret]);

  return (
    <div className="integration-inputs">
      <div className="integration-inputs-body">
        {modalOpen && (
          <PowerPlatformIntegrationAccountConfirmationModal
            open={modalOpen}
            setOpen={setModalOpen}
            clientId={clientId}
            platformTenantId={platformTenantId}
            clientSecret={clientSecret}
          />
        )}
        <div className="logo">
          <img src={logo} alt="Nokod Security" />
        </div>
        <p>
          <h1>{t('welcome.welcomeToNokodSecurity')}</h1>
          <div className="text">{t('welcome.welcomeMessage')}</div>
        </p>
        <p>
          <InputWithInstructions
            value={platformTenantId}
            setValue={setPlatformTenantId}
            placeholder={t('tenantField.tenantIdPlaceholder')}
            description={t('tenantField.tenantIdDescription')}
            error={platformTenantIdHasError ? t('tenantField.tenantIdError') : null}
          />

          <InputWithInstructions
            value={clientId}
            setValue={setClientId}
            placeholder={t('clientIdField.clientIdPlaceholder')}
            description={t('clientIdField.clientIdDescription')}
            error={clientIdHasError ? t('clientIdField.clientIdError') : null}
          />

          <InputWithInstructions
            value={clientSecret}
            setValue={setClientSecret}
            placeholder={t('clientSecretField.clientSecretPlaceholder')}
            description={t('clientSecretField.clientSecretDescription')}
            error={clientSecretHasError ? t('clientSecretField.clientSecretError') : null}
            type="password"
          />
        </p>

        <div className="footer">
          <Button
            text={t('general.submit', { ns: 'common' })}
            onClick={() => {
              setModalOpen(true);
              AzureAuthConfig.setConfig(clientId, platformTenantId);
              dispatch(setAzureConfigFinished());
            }}
            disabled={!canSubmit || loading}
          />
        </div>
      </div>
    </div>
  );
}
