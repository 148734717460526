/* eslint-disable no-param-reassign */
import axios from 'axios';
import * as qs from 'qs';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { partition } from 'lodash';
import { doGet, doPost } from '../../services/HttpService';
import { getTenantNameFromUrl } from '../../services/OrgNameService';
import { EnvironmentType } from '../../pages/integration/power-platform/EnvironmentType';
import { clearMsalKeys, getTokenData } from '../../services/AuthService';
import { AzurePermissions } from '../../auth/azure-auth-config';

export const fetchAccounts = createAsyncThunk('account/fetchAccounts', async () => {
  const res = await doGet('accounts');

  return res.data;
});

export const fetchPowerPlatformEnvironments = createAsyncThunk(
  'account/fetchPowerPlatformEnvironments',
  async ({ accountId, adminAccessToken }) => {
    const res = await doPost(`account/${accountId}/platform-environments`, {
      adminAccessToken,
    });

    return res.data;
  },
);

export const createPowerPlatformIntegration = createAsyncThunk(
  'account/createPowerPlatformIntegration',
  async ({ platformTenantId, clientId, clientSecret, accessToken, email }) => {
    const tenantName = getTenantNameFromUrl();
    const res = await doPost('account', {
      name: `${tenantName} Power Platform`,
      platform: 'Power Apps',
      authenticationData: {
        clientId,
        clientSecret,
      },
      data: {
        platformTenantId,
        clientId,
      },
      adminAccessToken: accessToken,
      email,
    });

    return res.data;
  },
);

async function getAccessTokenForEnv(platformTenantId, refreshToken, instanceUrl) {
  try {
    const res = await axios.post(
      `https://login.microsoftonline.com/${platformTenantId}/oauth2/v2.0/token`,
      qs.stringify({
        grant_type: 'refresh_token',
        scope: `${instanceUrl}/.default`,
        refresh_token: refreshToken,
      }),
    );
    return res.data.access_token;
  } catch (e) {
    throw e?.message ? { message: e.message, status: e.status } : e;
  }
}

function getTokenDataAndValidate(clientId, type, scope) {
  const { secret: token, expiresOn } = getTokenData(clientId, type, scope) || {};
  const isTokenExpired = new Date(Number(expiresOn)).getTime() < Date.now() / 1000;

  if (!token || isTokenExpired) {
    clearMsalKeys(clientId);
    throw new Error('Power Platform integration is incomplete. Please refresh the page');
  }
  return token;
}

export const createPowerPlatformEnvironments = createAsyncThunk(
  'account/createPowerPlatformEnvironment',
  async ({ accountId, environments, clientId, platformTenantId, email, powerBiEnvExists }) => {
    const refreshToken = getTokenDataAndValidate(clientId, 'refreshToken');
    const powerPlatformToken = getTokenDataAndValidate(
      clientId,
      'accessToken',
      AzurePermissions.POWER_PLATFORM_USERS_APPLY,
    );

    const promises = environments.map(async ({ name, isProduction, platformEnvId, instanceUrl, instanceApiUrl }) => {
      const accessToken = await getAccessTokenForEnv(platformTenantId, refreshToken, instanceUrl);
      return doPost(`account/${accountId}/environment`, {
        name,
        isProduction,
        email,
        type: EnvironmentType.Default,
        data: {
          environmentId: platformEnvId,
          instanceApiUrl,
        },
        instanceUrl,
        instanceApiUrl,
        clientId,
        platformTenantId,
        accessToken,
        powerPlatformToken,
      });
    });

    if (!powerBiEnvExists) {
      // send power BI request at the end because it has lower priority, and lower risk to fail.
      // We want to save the other environments first.
      const powerBiEnv = await doPost(`account/${accountId}/environment`, {
        name: 'Power BI',
        isProduction: true,
        type: EnvironmentType.PowerBI,
        email,
        data: {},
      });
      promises.push(powerBiEnv);
    }

    const envResults = await Promise.allSettled(promises);
    const [saved, failures] = partition(envResults, ({ status }) => status === 'fulfilled');

    if (!saved.length) {
      throw new Error('Failed to integrate environments');
    }

    return {
      succeedEnvs: saved.map(({ value }) => value?.data),
      failuresEnvs: failures.map((res) => {
        let envName = null;
        if (typeof res.reason?.message === 'string') {
          const url = res.reason?.message.match(/https:\/\/.*\.com/)?.[0];
          const environment = environments.find((env) => env.instanceApiUrl === url);
          envName = environment?.name;
        }

        return {
          reason: res.reason,
          name: envName,
        };
      }),
    };
  },
);

const initialState = {
  error: false,
  loading: true,
  content: [],
  azureConfigFinished: false,
  powerPlatform: {
    integrationCreate: {
      error: false,
      loading: false,
      errorMessage: null,
    },
    environments: {
      content: {
        succeedEnvs: [],
        failuresEnvs: [],
      },
      error: false,
      loading: false,
      errorMessage: null,
      success: false,
    },
  },
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAccounts.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchAccounts.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.content = payload;
    });
    builder.addCase(fetchAccounts.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(createPowerPlatformIntegration.pending, (state) => {
      state.powerPlatform.integrationCreate.loading = true;
      state.powerPlatform.integrationCreate.error = false;
    });
    builder.addCase(createPowerPlatformIntegration.fulfilled, (state, { payload }) => {
      state.powerPlatform.integrationCreate.loading = false;
      state.powerPlatform.integrationCreate.error = false;
      state.powerPlatform.environments.content.succeedEnvs = payload.additionalData.environments;
      state.content.push(payload.account);
    });
    builder.addCase(createPowerPlatformIntegration.rejected, (state, { error }) => {
      state.powerPlatform.integrationCreate.loading = false;
      state.powerPlatform.integrationCreate.error = true;
      state.powerPlatform.integrationCreate.errorMessage = error.message;
    });
    builder.addCase(fetchPowerPlatformEnvironments.pending, (state) => {
      state.powerPlatform.environments.loading = true;
      state.powerPlatform.environments.error = false;
    });
    builder.addCase(fetchPowerPlatformEnvironments.fulfilled, (state, { payload }) => {
      state.powerPlatform.environments.loading = false;
      state.powerPlatform.environments.error = false;
      state.powerPlatform.environments.content.succeedEnvs = payload;
    });
    builder.addCase(fetchPowerPlatformEnvironments.rejected, (state) => {
      state.powerPlatform.environments.loading = false;
      state.powerPlatform.environments.error = true;
    });
    builder.addCase(createPowerPlatformEnvironments.pending, (state) => {
      state.powerPlatform.environments.loading = true;
      state.powerPlatform.environments.error = false;
      state.powerPlatform.environments.success = false;
    });
    builder.addCase(createPowerPlatformEnvironments.fulfilled, (state, { payload }) => {
      state.powerPlatform.environments.loading = false;
      state.powerPlatform.environments.error = false;
      state.powerPlatform.environments.success = true;
      state.powerPlatform.environments.content = payload;
    });
    builder.addCase(createPowerPlatformEnvironments.rejected, (state, { error }) => {
      state.powerPlatform.environments.loading = false;
      state.powerPlatform.environments.error = true;
      state.powerPlatform.environments.errorMessage = error.message;
      state.powerPlatform.environments.success = false;
    });
  },
  reducers: {
    setAzureConfigFinished: (state) => {
      state.azureConfigFinished = true;
    },
    resetPowerPlatformAccountState: (state) => {
      state.powerPlatform.integrationCreate = initialState.powerPlatform.integrationCreate;
    },
    resetPowerPlatformEnvsError: (state) => {
      state.powerPlatform.environments.error = false;
      state.powerPlatform.environments.errorMessage = null;
    },
    changeIsProduction(state, action) {
      state.powerPlatform.environments.content.succeedEnvs = state.powerPlatform.environments.content.succeedEnvs.map(
        (env) => {
          if (action.payload.ids.includes(env.platformEnvId)) {
            return { ...env, isProduction: action.payload.isProduction };
          }
          return env;
        },
      );
    },
  },
});

export const {
  setAzureConfigFinished,
  resetPowerPlatformAccountState,
  resetPowerPlatformEnvsError,
  changeIsProduction,
} = accountSlice.actions;

// this is for configureStore
export default accountSlice.reducer;
