import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseableModal from '../../../../components/modal/ClosableModal';
import { getNotificationChannels } from '../../../../redux/selector/notificationSelector';
import Button from '../../../../components/buttons/button/Button';
import Input from '../../../../components/input/Input';
import DropdownBox from '../../../../components/dropdown-box/DropdownBox';
import { sendInsightNotifications } from '../../../../redux/slicers/insightSlicer';
import Toast from '../../../../components/toast/Toast';
import { getNotificationError, getNotificationsLoading } from '../../../../redux/selector/insightsSelector';
import Loader from '../../../../components/loader/Loader';
import { validateEmails } from '../../../../services/utils';
import { isSuperAdmin } from '../../../../redux/selector/authSelector';
import { NotificationChannelName } from '../../../settings/notifications/NotificationChannelName';
import { NotificationChannelType } from '../../../settings/notifications/NotificationConsts';

const SendOptions = Object.freeze({
  INSIGHT_OWNER: 'insightOwner',
  CHANNEL: 'channel',
  CUSTOM_EMAIL: 'customEmail',
});

const MAX_MESSAGE_SIZE = 500;

function ChannelOption({ notificationChannels, selectedChannel, setSelectedChannel }) {
  const { t } = useTranslation(['notifications']);

  const channelComponents = notificationChannels.map((channel) => ({
    id: channel.id,
    onClick: () => setSelectedChannel(channel),
    text: <NotificationChannelName name={channel.name} type={channel.type} />,
  }));

  return (
    <DropdownBox
      width={240}
      text={t('chooseChannel')}
      selectedId={selectedChannel.id}
      value={channelComponents.find((channel) => channel.id === selectedChannel.id).text}
      menuItems={channelComponents}
    />
  );
}

function NotificationOption({ value, label, isSelected, children }) {
  return (
    <div className="option">
      <FormControlLabel value={value} control={<Radio size="small" checked={isSelected} />} label={label} />
      {isSelected && children}
    </div>
  );
}

export default function SendNotificationModal({ open, setOpen, insightId, insightOwnerEmail }) {
  const { user } = useAuth0();
  const { t } = useTranslation(['notifications', 'common']);

  const isSuperAdminRole = useSelector(isSuperAdmin);
  const dispatch = useDispatch();
  const error = useSelector(getNotificationError);
  const loading = useSelector(getNotificationsLoading);
  const notificationChannels = useSelector(getNotificationChannels);
  const notificationChannelsToShow = (notificationChannels || [])
    .filter((channel) => channel.type !== NotificationChannelType.DYNAMIC_EMAIL)
    .filter((channel) => isSuperAdminRole || !channel.destination?.toLowerCase().includes('@nokodsecurity.com'));

  const defaultOption = insightOwnerEmail ? SendOptions.INSIGHT_OWNER : SendOptions.CHANNEL;
  const [option, setOption] = useState(defaultOption);
  const [selectedChannel, setSelectedChannel] = useState(null);

  const [message, setMessage] = useState('');
  const [messageValid, setMessageValid] = useState(true);
  const [customEmail, setCustomEmail] = useState('');
  const [customEmailError, setCustomEmailError] = useState(null);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (!selectedChannel && notificationChannelsToShow.length) {
      setSelectedChannel(notificationChannelsToShow[0]);
    }
  }, [notificationChannels]);

  // nullify the error when the user types
  useEffect(() => {
    setCustomEmailError(null);
  }, [customEmail]);

  function closeModal() {
    setOption(defaultOption);
    setCustomEmail('');
    setCustomEmailError(null);
    setMessage('');
    setMessageValid(true);
    setOpen(false);
  }

  async function handleOnClick() {
    if (option === SendOptions.CUSTOM_EMAIL) {
      const emailError = validateEmails(customEmail);
      if (emailError) {
        setCustomEmailError(emailError);
        return;
      }
    }

    let targets = [];
    switch (option) {
      case SendOptions.INSIGHT_OWNER:
        targets = [insightOwnerEmail];
        break;
      case SendOptions.CUSTOM_EMAIL:
        targets = customEmail.split(',').map((email) => email.trim());
        break;
      case SendOptions.CHANNEL:
      default:
        break;
    }

    await dispatch(
      sendInsightNotifications({
        insightId,
        notificationChannelId: option === SendOptions.CHANNEL ? selectedChannel.id : null,
        targets,
        senderName: user.name,
        message,
      }),
    );

    setShowToast(true);

    closeModal();
  }

  return (
    <>
      <Toast
        open={showToast}
        isSuccess={!error}
        setOpen={setShowToast}
        title={t(error ? 'sendFailedMessage' : 'sentSuccessfullyMessage')}
      />
      <CloseableModal open={open} closeModal={() => closeModal()}>
        <div className="title">{t('sendNotificationsManually')}</div>
        {loading ? (
          <Loader />
        ) : (
          <div className="notification-option-container">
            <div className="notification-option-form">
              <RadioGroup
                row
                id="option"
                defaultValue={SendOptions.INSIGHT_OWNER}
                label={t('general.option', { ns: 'common' })}
                onChange={(e) => setOption(e.target.value)}
              >
                {insightOwnerEmail && (
                  <NotificationOption
                    value={SendOptions.INSIGHT_OWNER}
                    label={t('emailInsightOwner')}
                    isSelected={option === SendOptions.INSIGHT_OWNER}
                  >
                    <div className="insight-owner-box">{insightOwnerEmail}</div>
                  </NotificationOption>
                )}
                {!!notificationChannelsToShow?.length && selectedChannel && (
                  <NotificationOption
                    value={SendOptions.CHANNEL}
                    label={t('sendToChannel')}
                    isSelected={option === SendOptions.CHANNEL}
                  >
                    <ChannelOption
                      notificationChannels={notificationChannelsToShow}
                      selectedChannel={selectedChannel}
                      setSelectedChannel={setSelectedChannel}
                    />
                  </NotificationOption>
                )}
                <NotificationOption
                  value={SendOptions.CUSTOM_EMAIL}
                  label={t('sendToEmail')}
                  isSelected={option === SendOptions.CUSTOM_EMAIL}
                >
                  <Input
                    value={customEmail}
                    setValue={setCustomEmail}
                    placeholder={t('useCommasToEnterMoreThanOneEmail')}
                    error={customEmailError}
                  />
                </NotificationOption>
              </RadioGroup>
            </div>
            <div>
              <div>{t('addCustomMessage')}</div>
              <TextField
                id="notification-message"
                multiline
                error={!messageValid}
                rows={4}
                placeholder={t('general.enterText', { ns: 'common' })}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setMessageValid(e.target.value.length <= MAX_MESSAGE_SIZE);
                }}
              />
            </div>
            <div className="chars-left">
              {MAX_MESSAGE_SIZE - message.length}/{MAX_MESSAGE_SIZE}
            </div>
          </div>
        )}
        <div className="buttons-row">
          <Button isFilled={false} text={t('general.cancel', { ns: 'common' })} onClick={() => closeModal()} />
          <Button
            isFilled
            text={t('general.approve', { ns: 'common' })}
            onClick={handleOnClick}
            disabled={!messageValid}
          />
        </div>
      </CloseableModal>
    </>
  );
}
