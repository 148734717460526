import { useSelector } from 'react-redux';
import PowerPlatformIntegrationInputPage from './power-platform/PowerPlatformIntegrationInputPage';
import PowerPlatformIntegrationEnvironmentsPage from './power-platform/PowerPlatformIntegrationEnvironmentsPage';
import {
  getAccounts,
  getAccountsLoading,
  getIsPowerPlatformIntegrationCompleted,
} from '../../redux/selector/accountsSelector';
import IntegrationCompletedPage from './IntegrationCompletedPage';
import { isEnvironmentsLoading } from '../../redux/selector/environmentsSelector';
import Loader from '../../components/loader/Loader';

function getPage(accounts, integrationCompleted, isLoading) {
  if (integrationCompleted) {
    return <IntegrationCompletedPage />;
  }
  if (accounts.length) {
    return <PowerPlatformIntegrationEnvironmentsPage />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return <PowerPlatformIntegrationInputPage />;
}

export default function IntegrationPage() {
  const accounts = useSelector(getAccounts);
  const accountsLoading = useSelector(getAccountsLoading);
  const environmentsLoading = useSelector(isEnvironmentsLoading);
  const isLoading = accountsLoading || environmentsLoading;
  const integrationCompleted = useSelector(getIsPowerPlatformIntegrationCompleted);
  const page = getPage(accounts, integrationCompleted, isLoading);

  return <div className="integration-page">{page}</div>;
}
