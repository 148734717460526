export const getInsightSummaries = (state) => state.insights.summaries.content;
export const getInsightTotal = (state) => state.insights.summaries.total.count;
export const getAllActiveInsightTotalCount = (state) => state.insights.aggregations.counters.content.insights || 0;
export const getAggregatedCount = (state) => state.insights.aggregations.counters.content.aggregations || 0;
export const getInsightCountersLoading = (state) => state.insights.aggregations.counters.loading;
export const getNotAggregatedInsightsCount = (state) => state.insights.aggregations.counters.content.notAggregated || 0;
export const getInsightSummariesFilters = (state) => state.insights.summaries.filters;
export const getInsightSummariesLoading = (state) => state.insights.summaries.loading;
export const getInsightsFiltersOptions = (state) => state.insights.summaries.filtersOptions;
export const getTotalInsightsCount = (state) => state.insights.summaries.total.count;

// single insight

export const getInsightData = (insightId) => (state) => state.insights.summaries.extended.content[insightId];
export const isInsightLoading = (state) => state.insights.summaries.extended.loading;
export const getApplicationInsights = (appId) => (state) => {
  const insights = Object.values(state.insights.summaries.extended.content);
  return insights.filter((i) => i.resource.id === appId);
};

// multi aggregations
export const getFilteredInsightAggregations = (state) => state.insights.aggregations.filtered.content;
export const getFilteredInsightAggregationsCount = (state) => state.insights.aggregations.filtered.total.count;
export const getInsightAggregationsLoading = (state) => state.insights.aggregations.filtered.loading;
export const getInsightAggregationsFilters = (state) => state.insights.aggregations.filters;
export const getInsightsAggregationsFiltersOptions = (state) => state.insights.aggregations.filtersOptions;
export const isInsightAcknowledgeFailed = (state) => state.insights.acknowledge.failed;

// single aggregation

export const getSingleAggregation = (aggId) => (state) => state.insights.aggregations.extended.content[aggId];

export const getInsightConfidentialErrorMessage = (state) => state.insights.confidential.message;

export const getNotificationsLoading = (state) => state.insights.notifications.loading;
export const getNotificationError = (state) => state.insights.notifications.error;

export const getInsightNotifications = (insightId) => (state) =>
  state.insights.notifications[insightId]?.notifications || [];

export const getInsightNextNotificationTime = (insightId) => (state) =>
  state.insights.notifications[insightId]?.nextNotificationTime;

export const getInsightGroupedByType = (state) => state.insights.insightsByType.content;
export const getInsightGroupedByTypeLoading = (state) => state.insights.insightsByType.loading;

export const getInsightGroupedBySeverity = (state) => state.insights.insightsBySeverity.content;
export const getInsightGroupedBySeverityLoading = (state) => state.insights.insightsBySeverity.loading;

export const getInsightRemediationContent = (state) => state.insights.remediate.content;
export const getInsightRemediationLoading = (state) => state.insights.remediate.loading;
export const getInsightRemediationError = (state) => state.insights.remediate.error;
