import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editNotificationPolicyError,
  editNotificationPolicyLoading,
  getNotificationPolicyById,
} from '../../../redux/selector/notificationSelector';
import ActionModal from '../../../components/modal/ActionModal';
import { updateNotificationPolicy } from '../../../redux/slicers/notificationsSlicer';

export default function ToggleNotificationPolicyModal({ policyId, modalOpen, closeModal }) {
  const { t } = useTranslation(['settings']);
  const { user } = useAuth0();
  const dispatch = useDispatch();

  const loading = useSelector(editNotificationPolicyLoading);
  const error = useSelector(editNotificationPolicyError);
  const policy = useSelector(getNotificationPolicyById(policyId));

  const toastTitle = t(
    `notifications.policiesTable.${policy?.isActive ? 'disable' : 'enable'}${error ? 'Failed' : 'Success'}`,
    {
      name: policy?.name,
    },
  );

  return (
    <ActionModal
      id="toggle-notification-policy-modal"
      title={t(`notifications.policiesTable.${policy?.isActive ? 'disable' : 'enable'}`)}
      onApprove={async () =>
        dispatch(
          updateNotificationPolicy({
            policyId,
            email: user.email,
            isActive: !policy.isActive,
          }),
        )
      }
      loading={loading}
      open={modalOpen}
      closeModal={closeModal}
      toastSuccessful={!error}
      toastTitle={toastTitle}
    >
      {t(t(`notifications.policiesTable.${policy?.isActive ? 'disable' : 'enable'}Confirmation`), {
        name: policy?.name,
      })}
    </ActionModal>
  );
}
