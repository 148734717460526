import classnames from 'classnames';
import './button.scss';

export default function Button({ isFilled = true, text, onClick, disabled = false }) {
  return (
    <button
      type="button"
      className={classnames('button', { filled: isFilled, disabled })}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
}
