import { getConnectorIcon } from '../../pages/data-source/connector-icons/connectorsIconsService';
import automationIcon from './icons/application_type_automation_icon.svg';
import cloudFlowIcon from './icons/application_type_cloud_flow_icon.svg';
import desktopFlowIcon from './icons/application_type_desktop_flow_icon.svg';
import canvasAppIcon from './icons/application_type_canvas_app_icon.svg';
import moduleDrivenAppIcon from './icons/application_type_module_driven_app_icon.svg';
import pageIcon from './icons/application_type_page_icon.svg';
import biIcon from './icons/application_type_bi_icon.svg';

export default function ApplicationType({ value }) {
  let icon;
  switch (value) {
    case 'Canvas App':
      icon = canvasAppIcon;
      break;
    case 'Automation':
      icon = automationIcon;
      break;
    case 'Cloud Flow':
      icon = cloudFlowIcon;
      break;
    case 'Desktop Flow':
      icon = desktopFlowIcon;
      break;
    case 'Module Driven App':
      icon = moduleDrivenAppIcon;
      break;
    case 'Portal':
      icon = pageIcon;
      break;
    case 'BI':
      icon = biIcon;
      break;
    case 'Embedded SharePoint':
      icon = getConnectorIcon('sharepoint');
      break;
    default:
      icon = canvasAppIcon;
  }

  return (
    <div className="icon-text">
      <img src={icon} alt="Connector icon" />
      <span>{value}</span>
    </div>
  );
}
