export const getAccounts = (state) => state.accounts.content;
export const getAccountsLoading = (state) => state.accounts.loading;
export const getAccountForRemediation = (state) =>
  (state?.accounts?.content || []).find((account) => account.data?.clientId);

// hasNoAccounts is used by the router
// it must return true ONLY if the request was successful and there are no accounts.
export const hasNoAccounts = (state) =>
  !state.accounts.loading && !state.accounts.error && !state.accounts.content.length;
export const getAzureConfigFinished = (state) => state.accounts.azureConfigFinished;
export const getAccountByPlatformName = (platformName) => (state) =>
  state.accounts.content.find((a) => a.platformName === platformName && a.data);

export const getPowerPlatformIntegrationState = (state) => state.accounts.powerPlatform.state;
export const isPowerPlatformIntegrationLoading = (state) => state.accounts.powerPlatform.integrationCreate.loading;
export const getPowerPlatformIntegrationErrorMessage = (state) =>
  state.accounts.powerPlatform.integrationCreate.errorMessage;
export const getIsPowerPlatformIntegrationCompleted = (state) => state.accounts.powerPlatform.environments.success;
export const getPowerPlatformIntegrationEnvironments = (state) => state.accounts.powerPlatform.environments.content;
export const isPowerPlatformEnvironmentsLoading = (state) => state.accounts.powerPlatform.environments.loading;
export const getPowerPlatformEnvironmentsErrorMessage = (state) =>
  state.accounts.powerPlatform.environments.errorMessage;
