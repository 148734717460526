import { useEffect, useRef, useState } from 'react';
import { isNil } from 'lodash';
import { Tooltip } from 'react-tooltip';
import { hashCode } from '../../services/StringUtils';

import './ellipsis-text.scss';

function EllipsisText({ text, className = '', containerMaxWidth, toolTipMaxWidth }) {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef(null);

  useEffect(() => {
    if (textElementRef?.current) {
      setIsOverflow(textElementRef.current?.scrollWidth > textElementRef.current?.clientWidth);
    }
  }, [text]);

  const hash = hashCode(isNil(text) ? '' : text.toString());

  return (
    <div className="text-ellipsis-container">
      <div
        data-tooltip-id={hash}
        data-tooltip-content={isOverflowed ? text : undefined}
        className={`text-wrapper ${className}`}
        style={{ maxWidth: containerMaxWidth }}
        ref={textElementRef}
      >
        {text}
      </div>
      <Tooltip id={hash} style={{ maxWidth: toolTipMaxWidth }} className="ellipsis-tooltip" />
    </div>
  );
}

export default EllipsisText;
