import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPowerPlatformIntegrationEnvironments } from '../../redux/selector/accountsSelector';
import Button from '../../components/buttons/button/Button';

export default function IntegrationCompletedPage() {
  const { t } = useTranslation(['onboarding']);
  const platformEnvironments = useSelector(getPowerPlatformIntegrationEnvironments);
  const { succeedEnvs, failuresEnvs } = platformEnvironments;

  return (
    <div className="integration-completed">
      <div className="emoji">🏆</div>
      <div className="green">{t('integrationComplete.done')}</div>
      <div>{t('integrationComplete.completed')}</div>
      <div className="green">
        {t('integrationComplete.completeSuccessMessage', { succeedCount: succeedEnvs.length })}
      </div>
      {!!failuresEnvs.length && (
        <div className="error">
          {t('integrationComplete.completeFailureMessage', { failureCount: failuresEnvs.length })}
          <div className="failed-envs-list">
            {failuresEnvs.map(
              (env) =>
                env?.name && (
                  <div key={env.id} className="failed-env">
                    {env.name}
                  </div>
                ),
            )}
          </div>
        </div>
      )}
      <div>{t('integrationComplete.data')}</div>
      <div className="buttons-row">
        <Button
          isFilled
          text={t('integrationComplete.backToEnvironmentsPage')}
          onClick={() => window.location.reload()}
        />
      </div>
    </div>
  );
}
