/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doDelete, doGet, doPatch, doPost } from '../../services/HttpService';
import { getNotificationChannels } from '../selector/notificationSelector';
import { NotificationChannelType } from '../../pages/settings/notifications/NotificationConsts';

function getFilterKey(reportType) {
  let filtersKey = '';
  if (reportType === 'insights') {
    filtersKey = 'insightFilters';
  } else if (reportType === 'applications') {
    filtersKey = 'applicationFilters';
  }
  return filtersKey;
}

function getCronExpression(interval) {
  switch (interval) {
    case 'daily':
      return '0 0 * * *';
    case 'weekly':
      return '0 0 * * 0';
    case 'monthly':
      return '0 0 1 * *';
    default:
      return '';
  }
}

export const fetchNotificationsChannels = createAsyncThunk('notifications/fetchAllChannels', async () => {
  const res = await doGet(`notification-channel`);

  return res.data;
});

export const deleteNotificationChannel = createAsyncThunk(
  'notificationsChannels/delete',
  async ({ channelId, body }) => {
    await doDelete(`notification-channel/${channelId}`, body);

    return channelId;
  },
);

export const updateNotificationChannel = createAsyncThunk(
  'notificationsChannels/update',
  async ({ channelId, body }) => {
    const res = await doPatch(`notification-channel/${channelId}`, body);

    return res.data;
  },
);

export const fetchNotificationPolicies = createAsyncThunk('notifications/fetchAllPolicies', async () => {
  const res = await doGet(`notification-policy`);

  return res.data;
});

export const deleteNotificationPolicy = createAsyncThunk('notificationsPolicies/delete', async ({ policyId, body }) => {
  await doDelete(`notification-policy/${policyId}`, body);

  return policyId;
});

export const createNotificationPolicy = createAsyncThunk('notificationsPolicies/create', async ({ body }) => {
  const res = await doPost(`notification-policy/`, body);

  return res.data;
});

export const updateNotificationPolicy = createAsyncThunk('notificationsPolicies/update', async ({ policyId, body }) => {
  const res = await doPatch(`notification-policy/${policyId}`, body);

  return res.data;
});

export const createNotificationChannel = createAsyncThunk('notificationsChannels/create', async ({ body }) => {
  try {
    const res = await doPost(`notification-channel`, body);

    return res.data;
  } catch (e) {
    throw new Error(typeof e === 'object' ? e.message || 'An error occurred' : e);
  }
});

export const fetchNotificationCountByChannel = createAsyncThunk(
  'notifications/fetchNotificationCountByChannel',
  async () => {
    // at the moment this is hard coded to be one month ago, but can be changed to be dynamic in the future
    const timestampMonthAgo = new Date();
    timestampMonthAgo.setMonth(timestampMonthAgo.getMonth() - 1);
    const since = timestampMonthAgo.toISOString();
    const res = await doGet(`notification-channel/count?since=${since}`);

    return res.data;
  },
);

export const scheduleReport = createAsyncThunk(
  'notificationsChannels/scheduleReport',
  async ({ name, interval, email, reportType, filters, auditUserName, auditUserEmail }, { getState, dispatch }) => {
    const notificationChannels = getNotificationChannels(getState());
    let notificationChannel = notificationChannels.find((channel) => channel.name === email);
    if (!notificationChannel) {
      const response = await dispatch(
        createNotificationChannel({
          body: { destination: email, type: NotificationChannelType.STATIC_EMAIL, name, auditUserName, auditUserEmail },
        }),
      );
      if (response.error) {
        throw new Error(response.error.message);
      }
      await dispatch(fetchNotificationsChannels());
      notificationChannel = response.payload;
    }

    const filtersKey = getFilterKey(reportType);
    const cronExpression = getCronExpression(interval);

    try {
      const res = await doPost(`report-metadata/${reportType}`, {
        [filtersKey]: filters,
        name,
        cronExpression,
        notificationChannelIds: [notificationChannel.id],
      });

      return res.data;
    } catch (e) {
      if (e.response) {
        throw new Error(e.response.data.message);
      }
      if (e.message) {
        throw new Error(e.message);
      }

      throw new Error('An error occurred');
    }
  },
);

const initialState = {
  channels: {
    loading: false,
    error: false,
    content: [],
    countByChannel: {
      loading: false,
      error: false,
      content: {},
    },
    create: {
      loading: false,
      error: false,
    },
    update: {
      loading: false,
      error: false,
    },
    delete: {
      loading: false,
      error: false,
    },
  },
  policies: {
    loading: false,
    error: false,
    content: [],
    create: {
      loading: false,
      error: false,
    },
    update: {
      loading: false,
      error: false,
    },
    delete: {
      loading: false,
      error: false,
    },
    editModeElement: {},
  },
  report: {
    loading: false,
    error: '',
    success: false,
    content: [],
  },
};

export const notificationChannelsSlice = createSlice({
  name: 'notifications',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationsChannels.pending, (state) => {
      state.channels.loading = true;
      state.channels.error = false;
    });
    builder.addCase(fetchNotificationsChannels.fulfilled, (state, { payload }) => {
      state.channels.loading = false;
      state.channels.error = false;
      state.channels.content = payload;
    });
    builder.addCase(fetchNotificationsChannels.rejected, (state) => {
      state.channels.loading = false;
      state.channels.error = true;
    });

    builder.addCase(deleteNotificationChannel.pending, (state) => {
      state.channels.delete.loading = true;
      state.channels.delete.error = false;
    });
    builder.addCase(deleteNotificationChannel.fulfilled, (state, { payload: channelId }) => {
      state.channels.delete.loading = false;
      state.channels.delete.error = false;
      // remove the deleted channel from the list
      state.channels.content = state.channels.content.filter((channel) => channel.id !== channelId);
    });
    builder.addCase(deleteNotificationChannel.rejected, (state) => {
      state.channels.delete.loading = false;
      state.channels.delete.error = true;
    });

    builder.addCase(updateNotificationChannel.pending, (state) => {
      state.channels.update.loading = true;
      state.channels.update.error = false;
    });
    builder.addCase(updateNotificationChannel.fulfilled, (state, { payload }) => {
      state.channels.update.loading = false;
      state.channels.update.error = false;
      // update the channel in the list
      state.channels.content = state.channels.content.map((channel) => {
        if (channel.id === payload.id) {
          return payload;
        }
        return channel;
      });
    });
    builder.addCase(updateNotificationChannel.rejected, (state) => {
      state.channels.update.loading = false;
      state.channels.update.error = true;
    });

    builder.addCase(createNotificationChannel.pending, (state) => {
      state.channels.create.loading = true;
      state.channels.create.error = false;
    });
    builder.addCase(createNotificationChannel.fulfilled, (state, { payload }) => {
      state.channels.create.loading = false;
      state.channels.create.error = false;
      state.channels.content.push(payload);
    });
    builder.addCase(createNotificationChannel.rejected, (state) => {
      state.channels.create.loading = false;
      state.channels.create.error = true;
    });
    builder.addCase(fetchNotificationCountByChannel.pending, (state) => {
      state.channels.countByChannel.loading = true;
      state.channels.countByChannel.error = false;
    });
    builder.addCase(fetchNotificationCountByChannel.fulfilled, (state, { payload }) => {
      state.channels.countByChannel.loading = false;
      state.channels.countByChannel.error = false;
      state.channels.countByChannel.content = payload;
    });
    builder.addCase(fetchNotificationCountByChannel.rejected, (state) => {
      state.channels.countByChannel.loading = false;
      state.channels.countByChannel.error = true;
    });

    // notification policies

    builder.addCase(fetchNotificationPolicies.pending, (state) => {
      state.policies.loading = true;
      state.policies.error = false;
    });
    builder.addCase(fetchNotificationPolicies.fulfilled, (state, { payload }) => {
      state.policies.loading = false;
      state.policies.error = false;
      state.policies.content = payload;
    });
    builder.addCase(fetchNotificationPolicies.rejected, (state) => {
      state.policies.loading = false;
      state.policies.error = true;
    });
    builder.addCase(createNotificationPolicy.pending, (state) => {
      state.policies.create.loading = true;
      state.policies.create.error = false;
    });
    builder.addCase(createNotificationPolicy.fulfilled, (state, { payload }) => {
      state.policies.create.loading = false;
      state.policies.create.error = false;
      state.policies.content.push(payload);
    });
    builder.addCase(createNotificationPolicy.rejected, (state) => {
      state.policies.create.loading = false;
      state.policies.create.error = true;
    });

    builder.addCase(deleteNotificationPolicy.pending, (state) => {
      state.policies.delete.loading = true;
      state.policies.delete.error = false;
    });
    builder.addCase(deleteNotificationPolicy.fulfilled, (state, { payload: policyId }) => {
      state.policies.delete.loading = false;
      state.policies.delete.error = false;
      // remove the deleted policy from the list
      state.policies.content = state.policies.content.filter((policy) => policy.id !== policyId);
    });
    builder.addCase(deleteNotificationPolicy.rejected, (state) => {
      state.policies.delete.loading = false;
      state.policies.delete.error = true;
    });

    builder.addCase(updateNotificationPolicy.pending, (state) => {
      state.policies.update.loading = true;
      state.policies.update.error = false;
    });
    builder.addCase(updateNotificationPolicy.fulfilled, (state, { payload }) => {
      state.policies.update.loading = false;
      state.policies.update.error = false;
      // update the policy in the list
      state.policies.content = state.policies.content.map((policy) => {
        if (policy.id === payload.id) {
          return payload;
        }
        return policy;
      });
    });
    builder.addCase(updateNotificationPolicy.rejected, (state) => {
      state.policies.update.loading = false;
      state.policies.update.error = true;
    });

    // scheduleReport

    builder.addCase(scheduleReport.pending, (state) => {
      state.report.loading = true;
      state.report.success = false;
      state.report.error = '';
    });
    builder.addCase(scheduleReport.fulfilled, (state, { payload }) => {
      state.report.loading = false;
      state.report.success = true;
      state.report.error = '';
      state.report.content = payload;
    });
    builder.addCase(scheduleReport.rejected, (state, { error }) => {
      state.report.loading = false;
      state.report.success = false;
      state.report.error = error.message;
    });
  },
  reducers: {
    resetCreateProcess: (state) => {
      state.report.error = '';
      state.channels.create.loading = false;
      state.channels.create.error = false;
      state.channels.create.init = false;
      state.report.success = false;
    },
    setPolicyFilters: (state, { payload }) => {
      state.policies.editModeElement.filters = payload.filters;
    },
  },
});

export const { resetCreateProcess, setPolicyFilters } = notificationChannelsSlice.actions;

export default notificationChannelsSlice.reducer;
