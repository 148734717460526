import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import BuildIcon from '@mui/icons-material/Build';
import { useSearchParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import AcknowledgeButton from '../aknowledge/AcknowledgeButton';
import ButtonWithIcon from '../buttons/button-with-icon/ButtonWithIcon';
import { isSuperAdmin } from '../../redux/selector/authSelector';
import { getAccountForRemediation } from '../../redux/selector/accountsSelector';
import { getLoginRequest } from '../../auth/azure-auth-config';
import AutoRemediateModal, { LinkRenderer } from './RemediationModal';

import logo from '../../pages/layout/navbar/logo_closed.svg';

import './remediation.scss';

export default function Remediation({ text = '', remediationActions, acknowledgeText, onAcknowledge, insights }) {
  const { t } = useTranslation(['remediation']);

  const [searchParams, setSearchParams] = useSearchParams();
  const [modelOpen, setModalOpen] = useState(false);
  const superAdmin = useSelector(isSuperAdmin);
  const accountForRemediation = useSelector(getAccountForRemediation);

  const activeInsightsCount = insights?.filter((i) => !i.status.toLowerCase().startsWith('closed')).length;
  const buttonsVisible = activeInsightsCount > 0;
  const currentInsight = insights?.[0];
  const scopeSubType = currentInsight?.scopeSubType;
  const isRemediationImplemented =
    accountForRemediation && currentInsight?.canRemediate && !!getLoginRequest(scopeSubType);
  const remediateActionsVisible = (!!remediationActions && superAdmin) || isRemediationImplemented;
  const isInRemediation = currentInsight?.status === 'IN_REMEDIATION';
  const remediate = searchParams.get('remediate');

  useEffect(() => {
    if (remediate) {
      setModalOpen(true);
      searchParams.delete('remediate');
      setSearchParams(searchParams, { replace: true });
    }
  }, []);

  return (
    <div className="remediation">
      <div className="icon-wrapper">
        <img src={logo} alt="logo" width={70} />
        <div>{t('remediation')}</div>
      </div>
      <div className="mitigation-wrapper">
        <div className="remediation-body">
          {text.split('\n\n').map((textPart) => (
            <>
              <Markdown remarkPlugins={[remarkGfm]} components={{ a: LinkRenderer }} key={textPart}>
                {textPart}
              </Markdown>
              <br />
            </>
          ))}
        </div>
        {buttonsVisible && (
          <div className="actions-container">
            <AcknowledgeButton
              text={acknowledgeText}
              onAcknowledge={onAcknowledge}
              insights={insights}
              buttonColor="green"
            />
            {remediateActionsVisible && (
              <>
                <AutoRemediateModal
                  platform={currentInsight?.platformName}
                  isDemo={!isRemediationImplemented}
                  insightId={currentInsight?.id}
                  modelOpen={modelOpen}
                  setModalOpen={setModalOpen}
                  remediationActions={remediationActions}
                  scopeSubType={scopeSubType}
                />
                <ButtonWithIcon
                  onClick={() => setModalOpen(true)}
                  text={t('remediate')}
                  color="blue filled"
                  enabled={!isInRemediation}
                  icon={<BuildIcon />}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
