import { useTranslation } from 'react-i18next';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ModelTrainingOutlinedIcon from '@mui/icons-material/ModelTrainingOutlined';
import { Tooltip } from 'react-tooltip';
import { StatusBullet } from '../../../components/status-bullet/StatusBullet';

export function UploadEmbedCodes({ reportStatus, loading }) {
  const { t } = useTranslation(['bi']);
  return (
    <>
      <div className="upload-embed-codes">
        <div className="img">
          <FileUploadOutlinedIcon />
        </div>
        <div>{t('uploadEmbedCodes.title')}</div>
      </div>
      <ReportsStatusBullet reportStatus={reportStatus} loading={loading} />
    </>
  );
}

export function EnrichWholeOrg({ reportStatus, loading }) {
  const { t } = useTranslation(['bi']);
  return (
    <>
      <div className="upload-embed-codes">
        <div className="img">
          <ModelTrainingOutlinedIcon />
        </div>
        <div>{t('enrichReports')}</div>
      </div>
      <ReportsStatusBullet reportStatus={reportStatus} loading={loading} />
    </>
  );
}

function ReportsStatusBullet({ reportStatus }) {
  const { t } = useTranslation(['bi']);
  const isSynced = reportStatus.enriched === reportStatus.total;
  const randomId = (Math.random() + 1).toString(36).substring(7);

  return (
    <div id={randomId}>
      <StatusBullet value={t(isSynced ? 'statusUpToDate' : 'statusOutdated')} active={isSynced} />
      {!isSynced && (
        <Tooltip anchorId={randomId}>
          <div>
            {t('reportsNotSynced', {
              notEnriched: reportStatus.total - reportStatus.enriched,
              total: reportStatus.total,
            })}
          </div>
        </Tooltip>
      )}
    </div>
  );
}
