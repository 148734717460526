import { Trans, useTranslation } from 'react-i18next';
import CopyableLink from '../../../components/copyable-link/CopyableLink';

export default function PowerPlatformIntegrationError({ isEnvironmentError = false }) {
  const { t } = useTranslation('onboarding');
  return (
    <div className="power-platform-integration-error">
      <div className="power-platform-integration-error-title">{t('conditionalAccessError.title')}</div>
      <div className="power-platform-integration-error-description">
        <div>{t('conditionalAccessError.generalDescription')}</div>
        <div>
          <Trans i18nKey="conditionalAccessError.description" t={t}>
            <CopyableLink
              text={t('conditionalAccessError.link')}
              link="https://entra.microsoft.com/#view/Microsoft_AAD_IAM/SignInEventsV3Blade/timeRangeType/last24hours/
              showApplicationSignIns~/true"
            />
          </Trans>
        </div>
      </div>
      {isEnvironmentError && (
        <div className="power-platform-integration-error-alternative">
          {t('conditionalAccessError.alternative')}
          <ol className="power-platform-integration-error-steps">
            <li>{t('conditionalAccessError.steps.step1')}</li>
            <li>
              {t('conditionalAccessError.steps.step2.title')}
              <ol type="a">
                <li>{t('conditionalAccessError.steps.step2.steps.a')}</li>
                <li>{t('conditionalAccessError.steps.step2.steps.b')}</li>
                <li>{t('conditionalAccessError.steps.step2.steps.c')}</li>
                <li>
                  {t('conditionalAccessError.steps.step2.steps.d.title')}
                  <ol type="i">
                    <li>{t('conditionalAccessError.steps.step2.steps.d.steps.i')}</li>
                    <li>{t('conditionalAccessError.steps.step2.steps.d.steps.ii')}</li>
                    <li>{t('conditionalAccessError.steps.step2.steps.d.steps.iii')}</li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      )}
    </div>
  );
}
