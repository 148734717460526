import sortUp from './sort-up.svg';
import sortDown from './sort-down.svg';
import sortNeutral from './sort-neutral.svg';
import ColumnFilter from './ColumnFilter';

export default function TableHeader({
  column,
  showSort,
  showFilter,
  columnOptions,
  filtersOptions,
  overrideSingleFilter,
  filter,
}) {
  const props = showSort ? column.getSortByToggleProps() : {};
  const allProps = column.getHeaderProps(props);
  const { key } = allProps;
  let imgSrc;
  if (column.isSorted) {
    imgSrc = column.isSortedDesc ? sortDown : sortUp;
  } else {
    imgSrc = sortNeutral;
  }

  allProps.style = {
    ...allProps.style,
    textAlign: 'left',
    fontWeight: 'bold',
    width: column.width,
    minWidth: column.minWidth,
  };

  const header = column.render('Header');
  // header can be a component and isn't always a string
  const isHeaderEmpty = typeof header !== 'string' || header.trim() === '';

  return (
    <th {...allProps} key={key} className="table-headers">
      <div className="table-header">
        <span>{header}</span>
        <ColumnFilter
          showFilter={showFilter}
          column={column}
          columnOptions={columnOptions}
          filter={filter}
          filtersOptions={filtersOptions}
          overrideSingleFilter={overrideSingleFilter}
        />
        {showSort && <span className="table-header-icon">{!isHeaderEmpty && <img alt="icon" src={imgSrc} />} </span>}
      </div>
    </th>
  );
}
