import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mapDate } from '../../services/MapperUtils';
import InternalLink from '../../components/internal-link/InternalLink';
import Table from '../../components/table/Table';
import TableEmptyContent from '../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../inventory/empty_applications.svg';
import BooleanValue from '../../components/boolean-value/BooleanValue';
import Tags from '../../components/tags/Tags';
import {
  getDeveloperFilterOptions,
  getDevelopers,
  getDevelopersFilters,
  getDevelopersLoading,
  getDevelopersTotal,
} from '../../redux/selector/userSelector';
import {
  fetchDevelopersFiltersOptions,
  updateDevelopersFiltersAndRefetchSummaries,
} from '../../redux/slicers/usersSlicer';
import PageHeader from '../../components/page-header/PageHeader';
import WidgetBar from '../../components/widget-bar/WidgetBar';
import EllipsisText from '../../components/ellipsis-text/EllipsisText';
import ActiveDevelopers from './ActiveDevelopers';
import DeveloperInsights from './DeveloperInsights';
import NewDevelopers from './NewDevelopers';
import { usersSinceOptions } from './UsersConsts';

import './user-page.scss';
import { ColumnType } from '../../components/table/ColumnOptions';
import OrganizationAwareLink from '../../components/organization-aware-link/OrganizationAwareLink';

const sinceOptions = Object.values(usersSinceOptions);

export default function UsersPage() {
  const users = useSelector(getDevelopers);
  const loading = useSelector(getDevelopersLoading);
  const total = useSelector(getDevelopersTotal);
  const developerFilterOptions = useSelector(getDeveloperFilterOptions);
  const dispatch = useDispatch();

  const { t } = useTranslation(['common', 'users']);

  useEffect(() => {
    (async function fetchData() {
      dispatch(fetchDevelopersFiltersOptions());
    })();
  }, []);

  const columns = useMemo(
    () => [
      {
        backendKey: 'name',
        width: '12%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.name,
        Cell: ({ cell }) => (
          <div className="auto-width">
            <OrganizationAwareLink to={`/users/${cell.row.original.id}`}>
              <EllipsisText text={cell.value} />
            </OrganizationAwareLink>
          </div>
        ),
      },
      {
        backendKey: 'email',
        width: '18%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.email,
      },
      {
        backendKey: 'lastActivity',
        type: ColumnType.DATE,
        width: '12%',
        accessor: (cell) => cell.lastActivity,
        Cell: ({ cell }) => <div className="gray">{cell.value ? mapDate(cell.value) : '-'}</div>,
      },
      {
        backendKey: 'environmentNames',
        width: '10%',
        accessor: (cell) => cell.environmentNames,
        Cell: ({ value, cell }) => {
          if (!value?.length) {
            return <div className="gray">N/A</div>;
          }

          const tags = value.map((tag) => ({ name: tag }));
          return <Tags tags={tags} tooltipAnchor={`environment-${cell.row.original.id}`} />;
        },
      },
      {
        backendKey: 'applicationsCount',
        width: '8%',
        type: ColumnType.NUMBER,
        accessor: (cell) => cell.applicationsCount,
        Cell: ({ value, cell }) => (
          <div className="connector-name-cell">
            {!Number(value) ? (
              0
            ) : (
              <InternalLink text={cell.value} url={`/applications?ownerId:is=${cell.row.original.id}`} />
            )}
          </div>
        ),
      },
      {
        backendKey: 'connectionsCount',
        width: '8%',
        type: ColumnType.NUMBER,
        accessor: (cell) => cell.connectionsCount,
        Cell: ({ cell }) => (
          <div className="gray">
            <InternalLink text={cell.value} url={`/data?tab=Connections&ownerId:is=${cell.row.original.id}`} />
          </div>
        ),
      },
      {
        backendKey: 'insightsCount',
        width: '6%',
        type: ColumnType.NUMBER,
        accessor: (cell) => cell.insightsCount,
        Cell: ({ cell }) => (
          <div className="gray">
            <InternalLink text={cell.value} url={`/insights?ownerId:is=${cell.row.original.id}&resolved:is=False`} />
          </div>
        ),
      },
      {
        backendKey: 'active',
        width: '5%',
        type: ColumnType.BOOLEAN,
        accessor: (cell) => cell.active,
        Cell: ({ cell }) => <BooleanValue isTrue={cell.value} />,
      },
      {
        backendKey: 'guest',
        width: '5%',
        type: ColumnType.BOOLEAN,
        accessor: (cell) => cell.guest,
        Cell: ({ cell }) => <BooleanValue isTrue={cell.value} />,
      },
    ],
    [],
  );

  const sortBy = [
    {
      id: 'Applications', // fixme: support translation
      desc: true,
    },
  ];

  return (
    <div className="users-page">
      <PageHeader title={t('developers', { ns: 'users' })} counter={total} />
      <WidgetBar
        boxes={[
          {
            title: t('mostActiveDevelopers', { ns: 'users' }),
            content: <ActiveDevelopers options={sinceOptions} />,
          },
          {
            title: t('developersWithMostInsights', { ns: 'users' }),
            content: <DeveloperInsights options={sinceOptions} />,
          },
          {
            title: t('newDevelopersTrend', { ns: 'users' }),
            content: <NewDevelopers />,
          },
        ]}
      />
      <Table
        columns={columns}
        data={users}
        options={{ showFilter: true, showHeaders: true }}
        type="users"
        sortBy={sortBy}
        exportPath="users/summary/csv"
        exportFileName="Nokod Users"
        fetchDataWithFilters={(filters, orderBy, order, page) => {
          dispatch(updateDevelopersFiltersAndRefetchSummaries({ filters, orderBy, order, page }));
        }}
        getFiltersSelector={getDevelopersFilters}
        customFilters={[]}
        contextualActions={[]}
        excludeFilters={[]}
        onRowSelected={() => {}}
        isRowDisabled={() => false}
        getElementId={(user) => user.id}
        filtersOptions={developerFilterOptions}
        isLoading={loading}
        totalCount={total}
        emptyState={
          <TableEmptyContent
            img={emptyApplicationsImg}
            mainText={t('noUsers', { ns: 'users' })}
            secondaryText={t('tables.makeDifferentFilterSelection')}
          />
        }
        defaultSearchColumn="name"
        defaultSearchColumnName={t('tables.users.columns.name')}
      />
    </div>
  );
}
