import { Doughnut } from 'react-chartjs-2';
import './doughnut-chart.scss';
import { sum } from 'lodash';
import { useNavigate } from 'react-router-dom';
import loadingImg from './loading_doughnut.svg';
import { navigateToSearch } from '../chartHelper';

const defaultScreenSize = 1920;

function getChartSize(size) {
  const screenSize = window.innerWidth;

  return screenSize > defaultScreenSize ? size * (screenSize / defaultScreenSize) : size;
}

export default function DoughnutChart({
  data,
  id = '',
  loading = false,
  tooltipDark = true,
  width = 120,
  height = 120,
  legendValues = true,
  legendPercentage = true,
  navigatePath = null,
  navigateQueryParam = null,
}) {
  const navigate = useNavigate();
  const canNavigate = !!(navigatePath && navigateQueryParam);

  const normalizedWidth = getChartSize(width);
  const normalizedHeight = getChartSize(height);

  if (loading) {
    return <img src={loadingImg} alt="loading-img" className="loading-img" width={width} />;
  }

  const legendContainer = `${id}-legend-container`;

  const total = sum(data.datasets[0].data);

  // make sure the minimum value is 1.5% to avoid the chart from being empty
  const inPercent = data.datasets[0].data.map((v) => (total === 0 || v === 0 ? 0 : Math.max((v / total) * 100, 1.5)));
  const dataCopy = { ...data, datasets: [{ ...data.datasets[0], data: inPercent }] };

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
      const updatedData = chart.config.data;
      const updatedTotal = sum(updatedData.datasets[0].data);

      const percentages = updatedData.datasets[0].data.map((value) =>
        updatedTotal === 0 ? 0 : Math.round((value / updatedTotal) * 100),
      );

      const table = document.getElementById(options.containerID).querySelector('table');

      // Remove old legend items
      while (table.firstChild) {
        table.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item) => {
        const tr = document.createElement('tr');
        tr.style.cursor = 'pointer';

        tr.onclick = () => {
          chart.toggleDataVisibility(item.index);
          chart.update();
        };

        const firstContainer = document.createElement('td');
        firstContainer.style.display = 'flex';

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.height = '11px';
        boxSpan.style.width = '11px';
        boxSpan.style.borderRadius = '50%';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.position = 'relative';
        boxSpan.style.top = '7px';

        firstContainer.appendChild(boxSpan);

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = 0;
        textContainer.style.padding = 0;
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);
        firstContainer.appendChild(textContainer);

        // Value
        const valueContainer = document.createElement('td');
        valueContainer.style.color = item.fontColor;
        valueContainer.style.margin = 0;
        valueContainer.style.padding = 0;
        valueContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        if (legendValues) {
          let value;
          if (legendPercentage) {
            value = percentages && percentages.length >= item.index ? `${percentages[item.index]}%` : '';
          } else {
            value =
              updatedData.datasets[0].data && updatedData.datasets[0].data.length >= item.index
                ? updatedData.datasets[0].data[item.index]
                : '';
          }

          const textNodeValue = document.createTextNode(value);
          valueContainer.appendChild(textNodeValue);
        }

        tr.appendChild(firstContainer);
        tr.appendChild(valueContainer);
        table.appendChild(tr);
      });
    },
  };

  return (
    <div className="doughnut-chart">
      <div id={legendContainer} data-testid={legendContainer} className="legend-container">
        <table />
      </div>
      <Doughnut
        data={dataCopy}
        width={normalizedWidth}
        height={normalizedHeight}
        options={{
          onClick(event, elements) {
            if (canNavigate && elements.length > 0) {
              const clickedElementIndex = elements[0].index;
              const clickedLabel = (data.englishLabels || [])[clickedElementIndex] || data.labels[clickedElementIndex];

              navigateToSearch(navigate, navigatePath, navigateQueryParam, clickedLabel);
            }
          },
          onHover: (event) => {
            // eslint-disable-next-line no-param-reassign
            event.native.target.style.cursor = canNavigate ? 'pointer' : 'default';
          },
          responsive: false,
          maintainAspectRatio: false,
          cutout: '65%',
          plugins: {
            tooltip: {
              backgroundColor: tooltipDark ? 'black' : 'white',
              titleColor: tooltipDark ? 'white' : 'black',
              bodyColor: tooltipDark ? 'white' : 'black',
              borderColor: tooltipDark ? 'white' : 'black',
              borderWidth: tooltipDark ? 0 : 1,
              callbacks: {
                label(tooltipItem) {
                  const value = data.datasets[0].data[tooltipItem.dataIndex];
                  const label = data.labels[tooltipItem.dataIndex];
                  return `${label}: ${value}`;
                },
              },
            },
            legend: {
              display: false,
            },
            htmlLegend: {
              containerID: legendContainer,
            },
          },
        }}
        plugins={[htmlLegendPlugin]}
      />
    </div>
  );
}
