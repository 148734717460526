import './key-value-element.scss';
import { isNil } from 'lodash';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

export default function KeyValueElement({ title, value, img, onClick, className, isLink = false }) {
  const { t } = useTranslation(['common']);

  return (
    <div className={classnames('key-value-element', className)} onClick={onClick}>
      {!isNil(title) && <div className="title">{`${title}:`}</div>}
      {!isNil(value) && (
        <div className={classnames('value', { isLink })}>
          {img && <img src={img} alt="logo" />}
          <div className="value-content">{value ?? t('general.unknown')}</div>
        </div>
      )}
    </div>
  );
}
