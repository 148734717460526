import { Snackbar } from '@mui/material';
import classNames from 'classnames';

import './toast.scss';

export default function Toast({ open, isSuccess, setOpen, title, message, closeTimeout = 2000 }) {
  const toastContent = (
    <div className="toast-content">
      <div>
        <div className="toast-title">{title}</div>
        <div>{message}</div>
      </div>
      <div className="toast-close" onClick={() => setOpen(false)}>
        &#10005;
      </div>
    </div>
  );

  return (
    <div
      className={classNames('toast', {
        success: isSuccess === true,
        failure: isSuccess === false,
      })}
    >
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        message={toastContent}
        autoHideDuration={closeTimeout}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </div>
  );
}
