import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { MsalProvider } from '@azure/msal-react';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import AzureAuthConfig from './auth/azure-auth-config';
import {
  clearRoles,
  clearTenantId,
  clearTenants,
  clearToken,
  getGuestToken,
  getTenantId,
  setAuthToken,
} from './services/AuthService';
import { getIsGuest, getOrganizations, getRoles } from './redux/selector/authSelector';
import { getAccountForRemediation, getAzureConfigFinished } from './redux/selector/accountsSelector';
import { fetchAccounts, setAzureConfigFinished } from './redux/slicers/accountSlicer';
import { fetchEnvironments } from './redux/slicers/environmentsSlicer';
import { fetchNotificationsChannels } from './redux/slicers/notificationsSlicer';
import { fetchOrganizationsAndRoles, fetchUser, setToken } from './redux/slicers/authSlicer';

import Router from './pages/layout/Router';
import ScrollToTop from './pages/layout/ScrollToTop';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Filler,
);

export default function App() {
  const dispatch = useDispatch();
  const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const roles = useSelector(getRoles);
  const organizations = useSelector(getOrganizations);
  const isGuest = useSelector(getIsGuest);
  const accountForRemediation = useSelector(getAccountForRemediation);
  useSelector(getAzureConfigFinished); // this needed for updating the azure config when we have the data

  function logoutFromPortal() {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    }).then(() => {
      clearTenantId();
      clearTenants();
      clearRoles();
      clearToken();
    });
  }

  async function refreshToken() {
    try {
      const token = await getAccessTokenSilently();
      setAuthToken(token);
      await dispatch(setToken(token));
    } catch (e) {
      console.error('Failed to refresh token', e);
      logoutFromPortal();
    }
  }

  useEffect(() => {
    if (accountForRemediation?.data) {
      const { clientId, platformTenantId } = accountForRemediation.data;
      AzureAuthConfig.setConfig(clientId, platformTenantId);
      dispatch(setAzureConfigFinished());
    }
  }, [accountForRemediation]);

  useEffect(() => {
    window.addEventListener('logout', logoutFromPortal);
  }, []);

  useEffect(() => {
    (async function () {
      if (user && isAuthenticated) {
        await refreshToken(); // initial call since setInterval doesn't execute immediately

        const intervalId = setInterval(() => {
          if (!getGuestToken()) {
            refreshToken();
          }
        }, 1000 * 30);

        // we need to execute it only once after login, otherwise it will override the tenant on refresh
        const { sub: userId } = user;
        if (!getTenantId()) {
          await dispatch(fetchUser(userId));
        }
        // this has to be after the tenantId and authToken are saved
        dispatch(fetchOrganizationsAndRoles(userId));
        return () => clearInterval(intervalId);
      }
      return () => {};
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isEmpty(roles) && organizations?.length) {
      dispatch(fetchEnvironments());
      dispatch(fetchNotificationsChannels());
      dispatch(fetchAccounts());
    }
  }, [roles, organizations]);

  useEffect(() => {
    if (isGuest) {
      dispatch(fetchEnvironments());
      dispatch(fetchAccounts());
    }
  }, [isGuest]);

  return (
    <MsalProvider instance={AzureAuthConfig.getInstance()}>
      <ScrollToTop />
      <Router />
    </MsalProvider>
  );
}
