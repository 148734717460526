export const getEnvironments = (state) => state.environments.environments;

export const hasNoEnvironments = (state) => state.environments.success && !state.environments.environments?.length;
export const isEnvironmentsLoading = (state) => state.environments.loading;

export const isEnvProd = (envId) => (state) => {
  const envs = state.data.environments.environments;
  if (!envs?.length) {
    return false;
  }

  return envs.find((env) => env.id === envId).isProduction;
};
