export const getUser = (state) => state.auth.user.content;
export const getOrganizations = (state) => state.auth.organizations.content;
export const isOrganizationsOrRolesHasError = (state) => state.auth.organizations.error || state.auth.roles.error;
export const getRoles = (state) => state.auth.roles.content;
export const isSuperAdmin = (state) => state.auth.roles.isSuperAdmin;
export const isAdmin = (state) => state.auth.roles.isAdmin;

export const getIsGuest = (state) => state.auth.guest.isGuest;

export const isOrganizationLoading = (state) => state.auth.organizations.loading;
