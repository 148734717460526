export const NotificationChannelType = Object.freeze({
  STATIC_EMAIL: 'Static Email Address',
  DYNAMIC_EMAIL: 'Dynamic Email Address',
  SLACK_CHANNEL: 'Slack Channel',
  SLACK_PRIVATE: 'Slack Private',
  TEAMS_CHANNEL: 'Teams Channel',
});

export const ChannelFields = {
  [NotificationChannelType.STATIC_EMAIL]: {
    name: 'email',
    fields: [
      {
        name: 'name',
        type: 'string',
        required: true,
        backendField: 'name',
      },

      {
        name: 'email',
        type: 'email',
        required: true,
        backendField: 'destination',
      },
    ],
  },
  [NotificationChannelType.SLACK_CHANNEL]: {
    name: 'slack',
    fields: [
      {
        name: 'name',
        type: 'string',
        required: true,
        backendField: 'name',
      },
      {
        name: 'webhook',
        type: 'password',
        required: true,
        backendField: 'token',
      },
    ],
  },
  [NotificationChannelType.TEAMS_CHANNEL]: {
    name: 'teams',
    fields: [
      {
        name: 'name',
        type: 'string',
        required: true,
        backendField: 'name',
      },
      {
        name: 'webhook',
        type: 'password',
        required: true,
        backendField: 'token',
      },
    ],
  },
};

export const ReminderInterval = Object.freeze({
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
});
