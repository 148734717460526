import { useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useAuth0 } from '@auth0/auth0-react';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import TableEmptyContent from '../../components/table/TableEmptyContent';
import emptyInsightsImg from './empty_insights.png';
import Table from '../../components/table/Table';
import SeverityBox from '../../components/box/SeverityBox';
import { compareSeverities, compareTypes } from '../../services/comparisonUtils';
import InsightTypeBox from '../../components/box/InsightTypeBox';
import { mapDate, mapSeverityFromCode } from '../../services/MapperUtils';
import InsightScope from '../../components/insight-scope/InsightScope';
import PlatformName from '../../components/platform-name/PlatformName';
import {
  acknowledgeInsights,
  fetchSingleAggregation,
  removeAggregation,
  updateAggregationFiltersAndRefetchSummaries,
} from '../../redux/slicers/insightSlicer';
import {
  getFilteredInsightAggregationsCount,
  getInsightAggregationsFilters,
  getInsightAggregationsLoading,
  getInsightsAggregationsFiltersOptions,
  getSingleAggregation,
  isInsightAcknowledgeFailed,
} from '../../redux/selector/insightsSelector';
import AcknowledgeModal from '../../components/aknowledge/AcknowledgeModal';
import { extractDataFromAggregationIdentity } from '../../services/aggregationUtils';
import AckToast from '../../components/toast/AckToast';
import { isAdmin, isSuperAdmin } from '../../redux/selector/authSelector';
import OrganizationAwareLink from '../../components/organization-aware-link/OrganizationAwareLink';
import { ColumnType } from '../../components/table/ColumnOptions';

export default function InsightAggregationTable({ data, initialFilters }) {
  const [message, setMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [ackCompleted, setAckCompleted] = useState(false);
  const [aggregationIdToAck, setAggregationIdToAck] = useState(null);
  const { user } = useAuth0();

  const dispatch = useDispatch();
  const insightAggregationsCount = useSelector(getFilteredInsightAggregationsCount);
  const filterOptions = useSelector(getInsightsAggregationsFiltersOptions);
  const loading = useSelector(getInsightAggregationsLoading);
  const insightsToAck = useSelector(getSingleAggregation(aggregationIdToAck))?.insights.data || [];
  const ackFailed = useSelector(isInsightAcknowledgeFailed);
  const isSuperAdminRole = useSelector(isSuperAdmin);
  const isAdminRole = useSelector(isAdmin);

  const { t } = useTranslation(['common', 'insights']);

  const isAllowedToAck = isSuperAdminRole || isAdminRole;

  async function handleAcknowledge(rowId) {
    if (!isAllowedToAck) {
      return;
    }
    await dispatch(fetchSingleAggregation(rowId));
    setAggregationIdToAck(rowId);
    setModalOpen(true);
  }

  const columns = useMemo(
    () => [
      {
        backendKey: 'severity',
        width: '12%',
        type: ColumnType.STRING,
        accessor: ({ severity }) => severity,
        Cell: ({ cell }) => <SeverityBox severity={mapSeverityFromCode(cell.value)} />,
        sortType: (rowA, rowB) => {
          const a = rowA.original.severity;
          const b = rowB.original.severity;
          return compareSeverities(a, b);
        },
      },
      {
        backendKey: 'insightType',
        width: '15%',
        type: ColumnType.STRING,
        accessor: ({ insightType }) => insightType,
        Cell: ({ cell }) => (
          <InsightTypeBox insightType={cell.value} hasBorder={false} isFilled={false} hasPadding={false} />
        ),
        sortType: (rowA, rowB) => {
          const a = rowA.original.insightType;
          const b = rowB.original.insightType;
          return compareTypes(a, b);
        },
      },
      {
        backendKey: 'scope',
        width: '14%',
        type: ColumnType.STRING,
        accessor: ({ scope }) => scope,
        Cell: ({ cell }) => <InsightScope insightScopeSubType={cell.value} />,
      },
      {
        backendKey: 'name',
        width: '40%',
        type: ColumnType.STRING,
        accessor: ({ name }) => name,
        Cell: ({ row, cell }) => {
          const { identity } = row.original;
          const pairs = extractDataFromAggregationIdentity(identity);

          return (
            <span
              id={row.id}
              data-tooltip-content={pairs
                .filter(([, value]) => !!value)
                .map(([key, value]) => `${key}: ${value}`)
                .join('\n')}
            >
              <OrganizationAwareLink to={`/aggregations/${row.id}?from=Aggregations`}>
                {capitalize(cell.value)}
              </OrganizationAwareLink>
              <Tooltip anchorId={row.id} />
            </span>
          );
        },
      },
      {
        backendKey: 'platformName',
        width: '13%',
        type: ColumnType.STRING,
        accessor: ({ platformName }) => platformName,
        Cell: ({ value }) => <PlatformName platform={value} />,
      },
      {
        backendKey: 'activeInsights',
        type: ColumnType.NUMBER,
        width: '10%',
        accessor: ({ activeInsights }) => activeInsights,
      },
      {
        backendKey: 'createdAt',
        type: ColumnType.DATE,
        width: '20%',
        accessor: ({ createdAt }) => createdAt,
        Cell: ({ cell }) => {
          const time = cell.value;
          return <span className="gray">{mapDate(time)}</span>;
        },
      },
      {
        Header: ' ',
        backendKey: '',
        width: '18%',
        Cell: ({ row }) => (
          <div
            role="button"
            tabIndex={0}
            className={`acknowledge-all gray ${!isAllowedToAck ? 'disabled' : ''}`}
            onClick={() => handleAcknowledge(row.id)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAcknowledge(row);
              }
            }}
          >
            <DoneAllIcon />
            <div>{t('acknowledgeAll', { ns: 'insights' })}</div>
          </div>
        ),
      },
    ],
    [isAllowedToAck],
  );

  const sortBy = [
    {
      id: 'Severity',
      desc: true,
    },
  ];

  async function doAck() {
    await dispatch(
      acknowledgeInsights({
        userEmail: user.email,
        userName: user.name,
        insightIds: insightsToAck.map((i) => i.id),
        message,
      }),
    );

    await dispatch(removeAggregation(aggregationIdToAck));
    setAckCompleted(true);
  }

  return (
    <>
      <AckToast ackFailed={ackFailed} ackCompleted={ackCompleted} setAckCompleted={setAckCompleted} />
      <AcknowledgeModal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        onAcknowledge={doAck}
        message={message}
        setMessage={setMessage}
        insights={insightsToAck}
      />
      <Table
        columns={columns}
        data={data}
        type="aggregations"
        sortBy={sortBy}
        initialFilters={initialFilters}
        totalCount={insightAggregationsCount}
        exportPath="aggregations/summary/csv"
        exportFileName="Nokod Insight Aggregations"
        fetchDataWithFilters={(filters, orderBy, order, page) => {
          dispatch(updateAggregationFiltersAndRefetchSummaries({ filters, orderBy, order, page }));
        }}
        getFiltersSelector={getInsightAggregationsFilters}
        getElementId={(aggregation) => aggregation.id}
        filtersOptions={filterOptions}
        isLoading={loading}
        emptyState={
          <TableEmptyContent
            img={emptyInsightsImg}
            mainText={t('noAggregations', { ns: 'insights' })}
            secondaryText={t('tables.makeDifferentFilterSelection')}
          />
        }
      />
    </>
  );
}
