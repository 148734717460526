import { useSelector } from 'react-redux';
import { chain, compact, startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import InsightAttributes from '../../../components/attribute/InsightAttributes';
import BasicTabs from '../../../components/basic-tabs/BasicTabs';
import { getEnvironments } from '../../../redux/selector/environmentsSelector';
import { mapDate } from '../../../services/MapperUtils';
import { getEnvironmentName } from '../../../services/EnvironmentUtils';

function insightDataPerType(value) {
  const { t } = useTranslation(['common']);

  const type = typeof value;
  switch (type) {
    case 'number':
      return value;
    case 'boolean':
      return value ? t('general.yes') : t('general.no');
    case 'string':
      // check if a date. isNaN(Number(value)) checks if it's not a string number.
      // The regex checks it isn't a version like 1.2.3
      return Date.parse(value) && Number.isNaN(Number(value)) && !/^\d+(\.\d+){2}$/.test(value)
        ? mapDate(value)
        : value;
    case 'object':
      if (Array.isArray(value)) {
        return value.join(', ');
      }
      console.warn(`unsupported type ${type} for value ${JSON.stringify(value)}`);

      return null;
    default:
      console.warn(`unsupported type ${type} for value ${value}`);
      return value;
  }
}

export default function AttributesTabs({ insight, application }) {
  const { t } = useTranslation(['single-insight']);

  const environments = useSelector(getEnvironments);

  function getInsightAttributes(relevantData) {
    const attributes = chain(relevantData)
      .values()
      .reduce((acc, value) => {
        Object.assign(acc, value);
        return acc;
      }, {})
      .value();

    return Object.entries(attributes).map(([key, value]) => ({
      title: startCase(key),
      value: insightDataPerType(value),
    }));
  }

  const tabsData = compact(
    (insight?.insightData || []).map((data) => {
      const attributes = getInsightAttributes(data.data);
      if (!attributes?.length) {
        return null;
      }
      const envName = getEnvironmentName(environments, data.environmentId);
      const lastVersion = application?.appInstances.find((v) => data.appInstanceId === v.id)?.version || '';
      return {
        label: (lastVersion ? t('environmentWithVersion', { envName, version: lastVersion }) : envName).trim(),
        content: <InsightAttributes attributes={attributes} insightId={insight.id} />,
      };
    }),
  );

  if (!tabsData.length) {
    return <div />;
  }

  return (
    <div className="attributes-tabs">
      <BasicTabs tabsData={tabsData} />
    </div>
  );
}
