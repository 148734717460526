import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CloseableModal from '../../../components/modal/ClosableModal';
import Input from '../../../components/input/Input';
import Checkbox from '../../../components/checkbox/Checkbox';
import Button from '../../../components/buttons/button/Button';
import Toast from '../../../components/toast/Toast';
import { createTag } from '../../../redux/slicers/dataTagSlicer';

const NEW_TAG_REGEX = /^[a-zA-Z0-9-_]+$/;

function AddTagModal({ modalOpen, closeModal }) {
  const { t } = useTranslation('common');
  const [name, setName] = useState('');
  const [sensitive, setSensitive] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [toastOpen, setToastOpen] = useState(false);
  const dispatch = useDispatch();

  function onClose() {
    setName('');
    setSensitive(false);
    setErrorMessages([]);
    closeModal();
  }

  async function addNewTag() {
    const errors = [];
    const sanitizedTagName = name.trim();
    const isValid = NEW_TAG_REGEX.test(sanitizedTagName);
    if (!sanitizedTagName) {
      errors.push(t('tags.manage.errors.tagNameRequired'));
    }
    if (!isValid) {
      errors.push(t('tags.manage.errors.tagNameInvalid'));
    }
    if (errors.length) {
      setErrorMessages(errors);
      return;
    }
    const res = await dispatch(createTag({ name: sanitizedTagName, sensitive }));
    if (res.error) {
      setErrorMessages([res.payload]);
      return;
    }
    onClose();
    setToastOpen(true);
  }

  return (
    <>
      <Toast
        isSuccess={!errorMessages.length}
        open={toastOpen}
        setOpen={setToastOpen}
        title={t('tags.manage.toast.successTitle')}
        message={t('tags.manage.toast.successMessage')}
      />
      <CloseableModal open={modalOpen} closeModal={onClose}>
          <div className="title">{t('tags.manage.addNewTag')}</div>
          <Input
            id="tag-name"
            value={name}
            setValue={setName}
            placeholder={t('tags.manage.placeholder')}
            className="full-size-input"
            required
          />
          <br />
          <Checkbox
          label={t('tags.manage.sensitive')}
            name="sensitive"
            value={sensitive}
            onChange={({ target }) => setSensitive(target.checked)}
          />
          <div className="error">
            {errorMessages.map((err) => (
              <li key={err}>{err}</li>
            ))}
          </div>
          <div className="buttons-row">
            <Button isFilled={false} text={t('tags.manage.buttons.cancel')} onClick={onClose} />
            <Button isFilled text={t('tags.manage.buttons.addTag')} onClick={addNewTag} />
        </div>
      </CloseableModal>
    </>
  );
}

export default AddTagModal;
