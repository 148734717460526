import './input.scss';
import classNames from 'classnames';

export default function Input({ id, icon, value, setValue, placeholder, type = 'text', required = true, error }) {
  return (
    <div className="input-container">
      <div className={classNames('input-holder', { error })}>
        {icon && <div className="icon">{icon}</div>}
        <div className="text">
          <input
            autoComplete="new-password" // removes autocomplete. Somewhat autoComplete off doesn't work
            type={type}
            id={id}
            value={value}
            placeholder={placeholder}
            onChange={(event) => setValue(event.target.value)}
            onKeyDown={(event) => event.stopPropagation()}
            required={required}
          />
        </div>
      </div>
      {error && (
        <div className="error">
          <div>Error:</div>
          <div>{error}</div>
        </div>
      )}
    </div>
  );
}
