import classnames from 'classnames';

export function StatusBullet({ value, active, loading }) {
  return (
    <div className="status-bullet">
      {!loading && <div className={classnames('bullet', { inactive: !active, active })} />}
      {value}
    </div>
  );
}
