export const getAllConnections = (state) => state.connection.allConnections.content || [];
export const getTotalConnections = (state) => state.connection.allConnections.total;
export const isConnectionsLoading = (state) => state.connection.allConnections.loading;
export const getSingleConnection = (connId) => (state) =>
  state.connection.singleConnection[connId]?.content?.connection;
export const getSingleConnectionUsers = (connId) => (state) =>
  state.connection.singleConnection[connId]?.content?.users;
export const getSingleConnectionData = (connId) => (state) => state.connection.singleConnection[connId]?.content?.data;
export const getSingleConnectionEmbeddedFlows = (connId) => (state) =>
  state.connection.singleConnection[connId]?.content?.embeddedFlows;
export const getSingleConnectionFlowEditors = (connId) => (state) =>
  state.connection.singleConnection[connId]?.content?.flowEditors;
export const getSingleConnectionLoading = (connId) => (state) => state.connection.singleConnection[connId]?.loading;
export const getSingleConnectionError = (connId) => (state) => state.connection.singleConnection[connId]?.error;

export const getConnectionFilters = (state) => state.connection.allConnections.filters;
export const getConnectionsFiltersOptions = (state) => state.connection.allConnections.filtersOptions;
