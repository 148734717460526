import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import KeyValueElement from '../../../components/key-value-element/KeyValueElement';
import { mapDate, mapSeverityFromCode } from '../../../services/MapperUtils';
import { mapStatus } from '../../../services/InsightsMapper';
import CircledText from '../../../components/circled-text/CircledText';
import ColoredCircledBoolean from '../../../components/circled-text/ColoredCircledBoolean';
import InsightScope from '../../../components/insight-scope/InsightScope';
import InsightTypeBox from '../../../components/box/InsightTypeBox';
import SeverityBox from '../../../components/box/SeverityBox';
import PersonData from '../../../components/person-data/PersonData';

export default function InsightData({
  rule,
  severity,
  description,
  history,
  scope,
  closedAt,
  owner,
  score,
  linksBreadcrumbs,
}) {
  const { t } = useTranslation(['common', 'single-insight']);

  // history is sorted. the most updated one is the first element

  const { inProduction, status, statusMessage } = history.at(0);
  const { changeTime } = history.at(-1);
  const { name, type } = rule;

  const hasJustification = status === 'CLOSED_ACKNOWLEDGED' && !!statusMessage;
  const hasClosedTime = !!closedAt;

  const statusElement = (
    <KeyValueElement
      title={t('general.status')}
      value={
        <CircledText
          text={mapStatus(status)}
          className={classnames({ 'in-remediation': status === 'IN_REMEDIATION' })}
        />
      }
    />
  );

  return (
    <div className="insight-data">
      <div className="row">
        <div className="rule">
          <div>{name}</div>
          <div>{description}</div>
        </div>
        <KeyValueElement title={t('insights.scope')} value={<InsightScope insightScopeSubType={scope} />} />
        <KeyValueElement title={t('general.creationTime')} value={mapDate(changeTime)} />
        <KeyValueElement
          title={t('insights.severity')}
          value={<SeverityBox severity={mapSeverityFromCode(severity)} />}
        />
      </div>

      <div className={classnames('row justification', { 'with-closed': hasClosedTime })}>
        {statusElement}

        {hasJustification ? (
          <KeyValueElement
            title={t('justification', { ns: 'single-insight' })}
            // react by default escapes html so this is safe
            // https://legacy.reactjs.org/docs/introducing-jsx.html#jsx-prevents-injection-attacks
            value={statusMessage}
          />
        ) : (
          <div />
        )}
        {owner && (
          <KeyValueElement
            title={t('insights.insightOwner')}
            value={<PersonData owner={owner} linksBreadcrumbs={linksBreadcrumbs} />}
            boxClassName="bordered"
          />
        )}
        <KeyValueElement
          title={t('general.inProduction')}
          value={<ColoredCircledBoolean isTrue={inProduction} />}
          boxClassName="bordered"
        />
        {hasClosedTime && <KeyValueElement title={t('insights.closeTime')} value={mapDate(closedAt)} />}
        <KeyValueElement
          title={t('general.type')}
          value={<InsightTypeBox insightType={type} hasBorder={false} isFilled={false} hasPadding={false} />}
          boxClassName="bordered"
        />
        <KeyValueElement title={t('insights.baseScore')} value={rule.riskScore || t('general.na')} />
        <KeyValueElement title={t('insights.priorityScore')} value={score || t('general.na')} />
      </div>
    </div>
  );
}
