import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import ApplicationsPage from '../inventory/ApplicationsPage';
import SingleApplicationPage from '../single/application/SingleApplicationPage';
import Layout from './Layout';
import InsightsPage from '../insights/InsightsPage';
import SingleInsightPage from '../single/insight/SingleInsightPage';
import HomePage from '../home/HomePage';
import PolicyPage from '../policy/PolicyPage';
import { CallbackPage } from './CallbackPage';
import AuthenticationGuard from './AuthentictionGuard';
import SingleAggregationPage from '../single-aggregation/SingleAggregationPage';
import DataSourcesPage from '../data-source/DataSourcesPage';
import { getGuestToken, getTenantId, getTenants, hasRoles, setTenantId } from '../../services/AuthService';
import { changeTenant, getTenantNameFromUrl } from '../../services/OrgNameService';
import {
  getOrganizations,
  getRoles,
  isOrganizationLoading,
  isOrganizationsOrRolesHasError,
} from '../../redux/selector/authSelector';
import ErrorPage from '../error/ErrorPage';
import { pushHistory } from '../../redux/slicers/historySlicer';
import SingleConnectionPage from '../connection/single-connection/SingleConnectionPage';
import UsersPage from '../users/UsersPage';
import { setGuestPermissions, setIsGuest } from '../../redux/slicers/authSlicer';
import PowerBiPage from '../bi/PowerBiPage';
import SingleUserPage from '../users/single-user/SingleUserPage';
import IntegrationPage from '../integration/IntegrationPage';
import { SystemErrorType } from '../error/errorConsts';
import { hasNoEnvironments } from '../../redux/selector/environmentsSelector';
import SettingsPage from '../settings/SettingsPage';

const pathsToIgnore = ['/', '/callback'];
export default function Router() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const organizationLoading = useSelector(isOrganizationLoading);
  const organizations = useSelector(getOrganizations);
  const roles = useSelector(getRoles);
  const hasNoEnvironmentsRes = useSelector(hasNoEnvironments);
  const organizationsOrRolesHasError = useSelector(isOrganizationsOrRolesHasError);

  const [errorType, setErrorType] = useState(null);

  const orgNameFromUrl = getTenantNameFromUrl();
  const availableTenants = getTenants();
  const currentTenantId = getTenantId();
  const currentTenant = availableTenants?.find((t) => t.name === currentTenantId);
  const currentTenantName = currentTenant?.display_name;

  const path = window.location.pathname;

  useEffect(() => {
    if (currentTenantName && hasNoEnvironmentsRes && !path.includes('integration') && !errorType) {
      navigate(`/${currentTenantName}/portal/integration`);
    } else if (!orgNameFromUrl && currentTenantName) {
      navigate(`/${currentTenantName}/portal`);
    }
    if (orgNameFromUrl !== currentTenantName) {
      const newTenant = organizations?.find((t) => t.display_name === orgNameFromUrl);
      if (newTenant) {
        changeTenant(dispatch, newTenant, window.location.pathname);
      }
    }
  }, [organizationLoading, organizations, currentTenantName, window.location.pathname, hasNoEnvironmentsRes]);

  const shouldIgnorePath = pathsToIgnore.some((pathToIgnore) => path === pathToIgnore);

  useEffect(() => {
    if (organizations && roles) {
      if (!organizations?.length) {
        setErrorType(SystemErrorType.NO_ORGANIZATION);
      } else if (!hasRoles(roles)) {
        setErrorType(SystemErrorType.NO_ROLE);
      } else if (
        !shouldIgnorePath &&
        (!path.includes('/portal') || !organizations?.some((org) => org.display_name === orgNameFromUrl))
      ) {
        setErrorType(SystemErrorType.ILLEGAL_ORGANIZATION);
      } else {
        setErrorType(null);
      }
    } else if (organizationsOrRolesHasError) {
      setErrorType(SystemErrorType.FAILED_TO_GET_USER_DATA);
    }
  }, [organizations, roles, path, organizationsOrRolesHasError]);

  useEffect(() => {
    dispatch(pushHistory(path));
  }, [path]);

  useEffect(() => {
    const token = getGuestToken();
    if (token) {
      try {
        const payload = jwtDecode(token);
        if (Date.now() > payload.exp * 1000) {
          setErrorType(SystemErrorType.EXPIRED_TOKEN);
          return;
        }
        setTenantId(payload.tenantId);
        dispatch(setGuestPermissions(payload.permissions));
        dispatch(setIsGuest(true));
      } catch (e) {
        console.error('Failed to decode token', e);
        setErrorType(SystemErrorType.INVALID_TOKEN);
      }
    } else {
      dispatch(setIsGuest(false));
      dispatch(setGuestPermissions([]));
    }
  }, [location, navigate]);

  useEffect(() => {
    if (errorType) {
      navigate('/error');
    }
  }, [path, errorType]);

  const goToMainRouting = (!errorType && path !== '/error') || path === '/';

  return (
    <Routes>
      <Route path="/:orgName/portal/integration" element={<AuthenticationGuard component={IntegrationPage} />} />
      <Route path="/" element={<Layout />}>
        {goToMainRouting ? (
          <>
            <Route path="/callback" element={<CallbackPage />} />
            <Route path="/" element={<AuthenticationGuard component={HomePage} />} />
            <Route path="/:orgName/portal" element={<AuthenticationGuard component={HomePage} />} />
            <Route
              path="/:orgName/portal/applications"
              element={<AuthenticationGuard component={ApplicationsPage} />}
            />
            <Route
              path="/:orgName/portal/applications/:appId"
              element={<AuthenticationGuard component={SingleApplicationPage} />}
            />
            <Route path="/:orgName/portal/insights" element={<AuthenticationGuard component={InsightsPage} />} />
            <Route
              path="/:orgName/portal/insights/:insightId"
              element={<AuthenticationGuard component={SingleInsightPage} />}
            />
            <Route path="/:orgName/portal/guest/insights/:insightId/" element={<SingleInsightPage />} />
            <Route
              path="/:orgName/portal/aggregations/:aggId"
              element={<AuthenticationGuard component={SingleAggregationPage} />}
            />
            <Route path="/:orgName/portal/data" element={<AuthenticationGuard component={DataSourcesPage} />} />
            <Route
              path="/:orgName/portal/data/:connId"
              element={<AuthenticationGuard component={SingleConnectionPage} />}
            />
            <Route path="/:orgName/portal/settings" element={<AuthenticationGuard component={SettingsPage} />} />
            <Route path="/:orgName/portal/users" element={<AuthenticationGuard component={UsersPage} />} />
            <Route path="/:orgName/portal/users/:userId" element={<AuthenticationGuard component={SingleUserPage} />} />
            <Route path="/:orgName/portal/bi" element={<AuthenticationGuard component={PowerBiPage} />} />
            <Route path="/:orgName/portal/policy" element={<AuthenticationGuard component={PolicyPage} />} />
            <Route path="*" element={<AuthenticationGuard component={ErrorPage} />} />
          </>
        ) : (
          <Route path="/error" element={<ErrorPage error={errorType} />} />
        )}
      </Route>
    </Routes>
  );
}
