import './platform-name.scss';
import { getLogo } from '../../services/LogoService';

export default function PlatformName({ platform }) {
  const logo = getLogo(platform);

  return (
    <div className="platform-name">
      <img src={logo} alt={`${platform} logo`} className="platform-logo" />
      <span>{platform}</span>
    </div>
  );
}
