/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGet } from '../../services/HttpService';
import { getTenantId } from '../../services/AuthService';

export const fetchRemediationByPlatformAndFeature = createAsyncThunk(
  'remediations/fetchRemediationByPlatformAndFeature',
  async ({ platformName, featureName }) => {
    const tenantId = getTenantId();
    const res = await doGet(
      `remediations?platformName=${platformName}&featureName=${featureName}&tenantId=${tenantId}`,
      false,
    );

    return {
      data: res.data,
    };
  },
);

const initialState = {
  loading: false,
  error: false,
  content: [],
};

export const remediationsSlice = createSlice({
  name: 'data',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchRemediationByPlatformAndFeature.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchRemediationByPlatformAndFeature.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      // add remediation only if it's not already in the list
      if (!state.content.find((remediation) => remediation.id === payload.data.id)) {
        state.content.push(payload.data);
      }
    });
    builder.addCase(fetchRemediationByPlatformAndFeature.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

// this is for configureStore
export default remediationsSlice.reducer;
