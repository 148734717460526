import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useAuth0 } from '@auth0/auth0-react';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AddTagModal from './AddTagModal';
import Toast from '../../../components/toast/Toast';
import ButtonWithIcon from '../../../components/buttons/button-with-icon/ButtonWithIcon';
import MultiSelectDropdownButton from '../../../components/multi-select-dropdown/MultiSelectDropdown';
import { fetchTags } from '../../../redux/slicers/dataTagSlicer';
import { getTags } from '../../../redux/selector/tagsSelector';
import {
  tagConnections,
  untagConnections,
  tagDataSources,
  untagDataSources,
} from '../../../redux/slicers/connectionsSlicer';

import './manage-tags-dropdown.scss';

const taggingStatus = Object.freeze({
  success: 'success',
  failed: 'failed',
});

function applySelectedTags(dataSources, tags) {
  return (tags || []).map((tag) => {
    const { name } = tag;
    const isSelected = !!dataSources.length && dataSources.every((d) => d.tags.some((t) => t.name === name));
    const isIndeterminate =
      !!dataSources.length && !isSelected && dataSources.some((d) => d.tags.some((t) => t.name === name));
    return {
      ...tag,
      id: name,
      label: name,
      isSelected,
      isIndeterminate,
    };
  });
}

export const TagResourceType = Object.freeze({
  DataSource: 'data-source',
  Connection: 'connection',
});

function SimpleManageButton({ handleButtonClicked }) {
  const { t } = useTranslation('common');
  return (
    <div className="simple-button" onClick={handleButtonClicked}>
      {t('tags.manage.title', { ns: 'common' })}
    </div>
  );
}

function DesignedManageButton({ handleButtonClicked }) {
  const { t } = useTranslation('common');
  return (
    <ButtonWithIcon
      icon={<LocalOfferOutlinedIcon width={6} />}
      onClick={handleButtonClicked}
      text={t('tags.manage.title', { ns: 'common' })}
      color="blue"
    />
  );
}

function ManageTagsDropdown({
  useSimpleButton = true,
  selectedResources,
  tagResourceType,
  connectionId,
  allowEdit = false,
}) {
  const { t } = useTranslation(['common', 'connections']);
  const { user } = useAuth0();
  const [mappedTags, setMappedTags] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isAddTagOpen, setIsAddTagOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [taggingState, setTaggingState] = useState(false);
  const tags = useSelector(getTags);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!tags?.length) {
        await dispatch(fetchTags());
      }
    })();
  }, []);

  useEffect(() => {
    const updatedTags = applySelectedTags(selectedResources, tags);
    setMappedTags(updatedTags || []);
  }, [tags, JSON.stringify(selectedResources)]);

  async function handleButtonClicked(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsAddTagOpen(true);
  }

  function handleClose() {
    setIsAddTagOpen(false);
    setAnchorEl(null);
  }

  function renderAddNewTagButton() {
    return (
      <Button
        className="add-button"
        onClick={() => {
          setModalOpen(true);
        }}
        startIcon={<AddIcon />}
      >
        {t('tags.manage.addNewTag', { ns: 'common' })}
      </Button>
    );
  }

  async function handleSelectForConnections(selectedTag, isChecked) {
    const handleTagChange = isChecked ? tagConnections : untagConnections;
    const connectionIds = selectedResources.map(({ id }) => id);

    return dispatch(handleTagChange({ connectionIds, tagNames: [selectedTag], email: user.email }));
  }

  async function handleSelectForData(selectedTag, isChecked) {
    const handleTagChange = isChecked ? tagDataSources : untagDataSources;
    const dataIds = selectedResources.map(({ id }) => id);
    return dispatch(handleTagChange({ dataIds, tagNames: [selectedTag], connectionId, email: user.email }));
  }

  async function handleSelect(selectedTag, isChecked) {
    const res =
      tagResourceType === TagResourceType.DataSource
        ? await handleSelectForData(selectedTag, isChecked)
        : await handleSelectForConnections(selectedTag, isChecked);

    setTaggingState(res.error ? taggingStatus.failed : taggingStatus.success);
    setToastOpen(true);
  }

  return (
    <div className="manage-tags-dropdown">
      {useSimpleButton ? (
        <SimpleManageButton handleButtonClicked={handleButtonClicked} />
      ) : (
        <DesignedManageButton handleButtonClicked={handleButtonClicked} />
      )}
      <AddTagModal modalOpen={modalOpen} closeModal={() => setModalOpen(false)} />
      <MultiSelectDropdownButton
        isOpen={isAddTagOpen}
        anchorEl={anchorEl}
        handleClose={handleClose}
        items={mappedTags}
        handleSelect={handleSelect}
        checkboxDisabled={!allowEdit && !selectedResources.length}
        footer={renderAddNewTagButton()}
      />
      <Toast
        isSuccess={taggingState === taggingStatus.success}
        open={toastOpen}
        setOpen={setToastOpen}
        title={t('taggingStatus.title', { ns: 'connections' })}
        message={t(taggingState === taggingStatus.success ? `taggingStatus.success` : `taggingStatus.failed`, {
          ns: 'connections',
        })}
      />
    </div>
  );
}

export default ManageTagsDropdown;
