import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NoData from '../../../components/no-data/NoData';
import { isApplicationRelatedFlowsLoading } from '../../../redux/selector/applictionSelector';
import FlowsTable from './FlowsTable';

export default function RelatedFlows({ linksBreadcrumbs, flows }) {
  const relatedFlowsLoading = useSelector(isApplicationRelatedFlowsLoading);
  const { t } = useTranslation(['single-application']);

  if (!flows?.length) {
    return <NoData text={t('noRelatedFlows')} />;
  }

  return (
    <div className="flow-requests">
      <FlowsTable flows={flows} isLoading={relatedFlowsLoading} from={linksBreadcrumbs} />
    </div>
  );
}
