import { useEffect, useState } from 'react';
import { startCase } from 'lodash';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import History from '../History';
import { getIsGuest } from '../../../redux/selector/authSelector';
import { getEnvironments } from '../../../redux/selector/environmentsSelector';
import { getInsightData, isInsightLoading } from '../../../redux/selector/insightsSelector';
import { getRemediationByPlatformAndFeature } from '../../../redux/selector/remediationsSelector';
import { getApplicationByInsight, isSingleApplicationLoading } from '../../../redux/selector/applictionSelector';
import { clearRemediation, fetchSingleInsight } from '../../../redux/slicers/insightSlicer';
import { fetchApplicationData } from '../../../redux/slicers/applicationSlicer';
import { fetchRemediationByPlatformAndFeature } from '../../../redux/slicers/remediationsSlicer';
import { mapDate } from '../../../services/MapperUtils';
import { mapStatus } from '../../../services/InsightsMapper';
import InsightData from './InsightData';
import InsightAppData from './InsightAppData';
import AttributesTabs from './AttributesTabs';
import Segment from '../../../components/segment/Segment';
import Remediation from '../../../components/remediation/Remediation';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import CircledText from '../../../components/circled-text/CircledText';
import KeyValueElement from '../../../components/key-value-element/KeyValueElement';
import ButtonWithIcon from '../../../components/buttons/button-with-icon/ButtonWithIcon';
import InsightGenericData from './InsightGenericData';
import NotificationsTab from './notification/NotificationsTab';
import SendNotificationModal from './notification/SendNotificationModal';
import notificationIcon from './notification/notification_icon.svg';

import './insight-page.scss';

export default function SingleInsightPage() {
  const [searchParams] = useSearchParams();
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [sendNotificationModalOpen, setSendNotificationModalOpen] = useState(false);
  const { insightId } = useParams();
  const isGuest = useSelector(getIsGuest);
  const dispatch = useDispatch();

  const insight = useSelector(getInsightData(insightId));
  const insightLoading = useSelector(isInsightLoading);
  const environments = useSelector(getEnvironments) || [];
  const remediation = useSelector(
    getRemediationByPlatformAndFeature(insight?.platformName, insight?.rule.predicates.join('')),
  );
  const application = useSelector(getApplicationByInsight(insight));
  const applicationLoading = useSelector(isSingleApplicationLoading);

  const { t } = useTranslation(['common', 'single-insight']);

  useEffect(() => {
    (async function fetchData() {
      if (!insight) {
        await dispatch(fetchSingleInsight(insightId));
      }
    })();

    return () => {
      dispatch(clearRemediation());
    };
  }, [isGuest]);

  useEffect(() => {
    if (insight && !remediation) {
      const { platformName } = insight;
      const featureName = insight.rule.predicates.join('');
      dispatch(fetchRemediationByPlatformAndFeature({ platformName, featureName }));
    }

    if (!application && insight?.resource.type === 'application') {
      dispatch(fetchApplicationData(insight.resource.id));
    }
  }, [insight, isGuest]);

  const isLoading = insightLoading || applicationLoading || !insight;

  const sortedHistory = (insight?.insightHistory || []).toSorted(
    (a, b) => new Date(b.changeTime).getTime() - new Date(a.changeTime).getTime(),
  );

  const isApplication = insight?.resource.type === 'application';
  const fromParam = searchParams.get('from') || '';
  const linksBreadcrumbs = `${fromParam},${insight?.rule.name}`;

  return (
    <div className="single-insight-page">
      {!isGuest && <Breadcrumbs current={insight?.rule.name} />}
      <div className="notification-button">
        <ButtonWithIcon
          icon={<img src={notificationIcon} alt="notify" />}
          text={t('notificationButton.notifyUser', { ns: 'single-insight' })}
          onClick={() => setSendNotificationModalOpen(true)}
          color="blue"
          filled
        />
        <ButtonWithIcon
          icon={<img src={notificationIcon} alt="show notifications" />}
          text={t('notificationButton.openNotificationPanel', { ns: 'single-insight' })}
          onClick={() => setNotificationsOpen(true)}
          color="blue"
        />
        <SendNotificationModal
          open={sendNotificationModalOpen}
          setOpen={setSendNotificationModalOpen}
          insightId={insightId}
          insightOwnerEmail={insight?.owner?.email}
        />
      </div>
      <NotificationsTab
        open={notificationsOpen}
        setOpen={setNotificationsOpen}
        insightId={insight?.id}
        insightOwnerEmail={insight?.owner?.email}
      />
      <Segment
        title={t('insights.scopeData', { scope: startCase(insight?.scopeSubType) })}
        isLoading={isLoading || (isApplication && !application)}
      >
        {isApplication ? (
          <InsightAppData insight={insight} application={application} linksBreadcrumbs={linksBreadcrumbs} />
        ) : (
          <InsightGenericData
            resourceId={insight?.resource.id}
            platformName={insight?.platformName}
            entityName={insight?.data?.entityName}
            scopeSubType={insight?.scopeSubType}
          />
        )}
      </Segment>
      <Segment title={t('insightData', { ns: 'single-insight' })} isLoading={isLoading}>
        <InsightData
          score={insight?.score}
          description={insight?.description}
          rule={insight?.rule}
          history={sortedHistory}
          scope={insight?.scopeSubType}
          closedAt={insight?.closedAt}
          owner={insight?.owner}
          severity={insight?.severity}
          linksBreadcrumbs={linksBreadcrumbs}
        />
      </Segment>
      <div className="attributes-container">
        <Segment title={t('insightAttributes', { ns: 'single-insight' })} isLoading={isLoading}>
          <AttributesTabs insight={insight} application={application} />
        </Segment>
      </div>
      <Remediation
        text={insight?.remediation}
        remediationActions={insight?.actions}
        acknowledgeText={t('insights.acknowledge')}
        insights={[insight].filter((i) => !!i && !i.status.toLowerCase().startsWith('closed'))}
        onAcknowledge={() => dispatch(fetchSingleInsight(insightId))}
      />
      <div className="insight-history">
        <Segment title={t('insightHistory', { ns: 'single-insight' })} isLoading={isLoading} background={false}>
          <InsightHistory sortedHistory={sortedHistory} environments={environments} />
        </Segment>
      </div>
    </div>
  );
}

function InsightHistory({ sortedHistory }) {
  const { t } = useTranslation(['common']);

  const historyCards = sortedHistory.map((history) => (
    <div key={history.id} className="history-card">
      <KeyValueElement title={t('general.changeTime')} value={mapDate(history.changeTime)} />
      <KeyValueElement
        title={t('general.changedBy')}
        value={history?.changedByName || history?.changedByEmail || t('general.unknown')}
      />
      <KeyValueElement title={t('general.status')} value={<CircledText text={mapStatus(history.status)} />} />
    </div>
  ));

  return <History cards={historyCards} />;
}
